import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Abtslider1 from '../../assets/images/abtslide_1.png';
import Abtslider2 from '../../assets/images/abtslide_2.png';
import Abtslider3 from '../../assets/images/abtslide_3.png';
import Abtslider4 from '../../assets/images/abtslide_4.png';
import Abtslider5 from '../../assets/images/abtslide_5.png';

const Slider = {
    responsive: {
        0: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
};

const abtSlideImages = [
    Abtslider1,
    Abtslider2,
    Abtslider3,
    Abtslider4,
    Abtslider5,
    Abtslider4,
    Abtslider4,
    Abtslider5,
    Abtslider4,
    Abtslider4,
    Abtslider5,
    Abtslider4,
];

function About() {
    return (
        <>
            <section className="about_us_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">About us</span>
                        <h2>
                          Built for students, <br /> <span>By students </span>
                        </h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row abt_text" data-aos="fade-up" data-aos-duration={1500}>
                        <div className="col-md-7">
                            <p>
                                Collegiate was founded on the realization that while the academic side of college life is well-supported,
                                 there's a whole other side to the student experience that often lacks the same level of integration and support.
                                 <br />  <br /> That's where Collegiate steps in. It's an all-in-one platform designed to enhance the student experience by simplifying how students engage with every aspect of campus life outside the classroom.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
