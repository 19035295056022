import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../assets/images/service11.png'
import service2 from '../../assets/images/service22.png'
import service3 from '../../assets/images/service33.png'
import "../../assets/css/responsive.css";

function Service() {
    return (
        <>
            <section className="row_am service_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        {/* <span className="title_badge mb-1">Our effect</span> */}
                        <h2>
                            How we make a <br /> <span>difference</span>
                        </h2>
                    </div>
                    <div className="row service_blocks">
                        <div className="col-md-6">
                            <div className="service_text" data-aos="fade-up" data-aos-duration={1500} >
                                <div className="service_badge">
                                    
                                    <span>Accessibility</span>
                                </div>
                                <h2>
                                    <span>Everything</span> students <br />
                                    need at their fingertips
                                </h2>
                                {/* <p>
                                   We provide students with an all in one spot to discover everything campus related.
                                </p> */}
                                <ul className="feature_list">
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Learn more about what your campus has to offer</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Find clubs, events, resources and study groups</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Collaborate and learn with classmates</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_block">
                                    {/* <Link to="/contact" className="btn puprple_btn ml-0">
                                        Learn More
                                    </Link>
                                    <div className="btn_bottom" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                                <img src={service1} alt="image" />
                            </div>
                        </div>
                    </div>
                    <div className="row service_blocks flex-row-reverse">
                        <div className="col-md-6">
                            <div className="service_text right_side"
                                data-aos="fade-up" data-aos-duration={1500} >
                                <div className="service_badge">
                                    {/* <i className="icofont-ui-clock" /> */}
                                    <span>Community</span>
                                </div>
                                <h2>
                                    Let students<span> connect </span>and <span> expand </span>their network
                                </h2>
                                <p>
                                   {/* Foster a more connected and engaged campus community. */}
                                </p>
                                <ul className="feature_list">
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Create study groups by classes</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Connect with students with similar interests</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Engage in community chats</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_block">
                                    {/* <Link to="/contact" className="btn puprple_btn ml-0">
                                        Learn More
                                    </Link>
                                    <div className="btn_bottom" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                                <img src={service2} alt="image" />
                            </div>
                        </div>
                    </div>
                    <div className="row service_blocks">
                        <div className="col-md-6">
                            <div
                                className="service_text"
                                data-aos="fade-up"
                                data-aos-duration={1500}
                            >
                                <div className="service_badge">
                                    {/* <i className="icofont-list" />  */}
                                    <span>Oversight</span>
                                </div>
                                <h2>
                                    All under <span>administrative </span>oversight and approval
                                </h2>
                                {/* <p>
                                Keep your campus safe. View student activity and manage security measures through administrator dashboard, ensuring a protected campus environment.
                                </p> */}
                                <ul className="feature_list">
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Approve student held events before they appear on the app</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Track and analyze campus activity trends for proactive safety measures.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Ability to remove anything deemed innapropriate</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_block">
                                    {/* <Link to="/contact" className="btn puprple_btn ml-0">
                                        Learn More
                                    </Link>
                                    <div className="btn_bottom" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                                <img src={service3} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Service