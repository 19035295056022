import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, getDoc, deleteDoc, doc, Timestamp, updateDoc, query, where, orderBy} from 'firebase/firestore';
import { firestore, storage} from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {auth, db } from '../firebase';
import './BulletinPage.css';
import Modal from 'react-modal';
import logo from './../assets/images/logo.png';

Modal.setAppElement('#root');

const customModalStyles = {
    content: {
        maxWidth: '50%', 
        maxHeight: '50%', 
        margin: 'auto', // Center the modal
        padding: '20px',
        borderRadius: '10px',
        position: 'relative', // Ensure it's centered
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent overlay
    },
};

const BulletinPage = () => {
    const [events, setEvents] = useState([]);
    const [eventRequests, setEventRequests] = useState([]);
    const [newEvent, setNewEvent] = useState({ name: '', imageURL: '', description: '', date: '', time: '', location: '', category: '', school: '',  websiteURL: '',  isApproved: false });
    const [selectedEvent, setSelectedEvent] = useState(null); // State to track the selected event for the modal
    const [currentActiveEventPage, setCurrentActiveEventPage] = useState(1);
    const [currentEventRequestPage, setCurrentEventRequestPage] = useState(1);
    const [adminSchool, setAdminSchool] = useState('')
    const [posterDetails, setPosterDetails] = useState({ name: '', email: '' });
    const [activeTab, setActiveTab] = useState('addEvents');


    useEffect(() => {
        const fetchAdminSchool = async () => {
            if (auth.currentUser) {
                const userDoc = await getDoc(doc(firestore, 'users', auth.currentUser.uid));
                if (userDoc.exists()) {
                    setAdminSchool(userDoc.data().school);
                }
            }
        };

        fetchAdminSchool();
    }, []);

    useEffect(() => {
        if (adminSchool) {
            fetchEvents(adminSchool);
        }
    }, [adminSchool]);

    const fetchEvents = async (school) => {
        // Fetch active events for the specific school
        const activeEventsQuery = query(collection(firestore, 'Events'), 
        where("isApproved", "==", true), 
        where("school", "==", school),
        orderBy("date", "asc")
        
        );
        const activeEventsData = await getDocs(activeEventsQuery);
        setEvents(processEventData(activeEventsData));

        // Fetch event requests for the specific school
        const eventRequestsQuery = query(collection(firestore, 'Events'), where("isApproved", "==", false), where("school", "==", school));
        const eventRequestsData = await getDocs(eventRequestsQuery);
        setEventRequests(processEventData(eventRequestsData));
    };

    const processEventData = (eventData) => {
        return eventData.docs.map(doc => {
            const data = doc.data();
    
            // Check if data.date is a Firestore Timestamp
            let eventDateTime;
            if (data.date && typeof data.date.toDate === 'function') {
                eventDateTime = data.date.toDate();
            } else if (data.date) {
                // Assuming data.date is already a JavaScript Date object or another compatible format
                eventDateTime = new Date(data.date);
            } else {
                eventDateTime = new Date(); // Default to current date if no date provided
            }
    
            return {
                ...data,
                id: doc.id,
                date: eventDateTime.toLocaleDateString(),
                time: eventDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };
        });
    };
    

    // Add event to Firestore
    {/*}
    const handleAddEvent = async (e) => {
        e.preventDefault();
        if (!newEvent.imageURL) {
            alert("Please select an image for the event.");
            return;
        }
    
        const eventDateTime = newEvent.date && newEvent.time ? new Date(`${newEvent.date}T${newEvent.time}`) : null;
        // Create a storage reference
        const storageRef = ref(storage, `events/${newEvent.imageURL.name}_${new Date().getTime()}`);

    
        try {
            // Upload image to Firebase Storage
            const imageSnapshot = await uploadBytes(storageRef, newEvent.image, { contentType: 'image/png' });
            const imageURL = await getDownloadURL(imageSnapshot.ref);
    
            // Once the image is uploaded, save the event data with the image URL
            const eventToSave = {
                ...newEvent,
                date: eventDateTime ? Timestamp.fromDate(eventDateTime) : null,
                isApproved: true,
                eventAttendees: [],
                school: adminSchool,
                imageURL: imageURL, 
            };
    
            await addDoc(collection(firestore, 'Events'), eventToSave);
            alert("Event added successfully!");
            // Clear form
            setNewEvent({ name: '', imageURL: null, description: '', date: '', time: '', location: '', category: '', school: '', isApproved: true });
            fetchEvents(adminSchool); // Refetch events after adding
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Error adding event!");
        }
    };
*/}
{/*}
const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
        const imageURL = e.target.files[0];
        
        setNewEvent(prevState => ({
            ...prevState,
            imageURL: imageURL// Make sure this matches how you reference the image in handleAddEvent
        }));
    }
};
*/}
const handleAddEvent = async (e) => {
    e.preventDefault();
    if (!newEvent.imageURL) {
        alert("Please select an image for the event.");
        return;
    }

    const eventDateTime = newEvent.date && newEvent.time ? new Date(`${newEvent.date}T${newEvent.time}`) : null;
    const fileExtension = newEvent.imageURL.name.split('.').pop(); // Extract file extension
    const fileName = `${newEvent.imageURL.name}_${new Date().getTime()}.${fileExtension}`;
    const storageRef = ref(storage, `events/${fileName}`);

    try {
        // Upload image to Firebase Storage
        const imageSnapshot = await uploadBytes(storageRef, newEvent.imageURL); // Use the file directly
        const imageURL = await getDownloadURL(imageSnapshot.ref);

        // Once the image is uploaded, save the event data with the image URL
        const eventToSave = {
            ...newEvent,
            dateTime: eventDateTime ? Timestamp.fromDate(eventDateTime) : null,
            isApproved: true,
            eventAttendees: [],
            school: adminSchool,
            imageURL: imageURL,
        };

        await addDoc(collection(firestore, 'Events'), eventToSave);
        alert("Event added successfully!");
        // Clear form
        setNewEvent({ name: '', imageURL: null, description: '', dateTime: '', time: '', location: '', category: '', school: '', websiteURL: '',  isApproved: true });
        fetchEvents(adminSchool); // Refetch events after adding
    } catch (error) {
        console.error("Error adding document: ", error);
        alert("Error adding event!");
    }
};

const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
        setNewEvent(prevState => ({
            ...prevState,
            imageURL: e.target.files[0] // Directly set the file from input
        }));
    }
};
    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({ ...newEvent, [name]: value });
    };




    const fetchUserDetails = async (publisher) => {
        try {
            const userRef = doc(db, 'users', publisher); // Create a reference to the user document
            const userDoc = await getDoc(userRef); // Fetch the document
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setPosterDetails({ name: userData.name, email: userData.email });
            } else {
                setPosterDetails({ name: 'Unknown', email: 'N/A' });
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
            // Optionally, update state to indicate the error
        }
    };
    
    


    // Delete event from Firestore
    const handleDeleteEvent = async (eventId) => {
        if (window.confirm("Are you sure you want to delete this event?")) {
            try {
                await deleteDoc(doc(firestore, "Events", eventId));
                alert("Event deleted successfully.");
                closeModal(); // Close the modal after deletion
                fetchEvents(); // Refresh the events list
            } catch (error) {
                console.error("Error deleting event: ", error);
                alert("Error deleting event.");
            }
        }
    };

    const handleApproveEventRequest = async (eventId) => {
        if (window.confirm("Are you sure you want to approve this event?")) {
            try {
                const eventDocRef = doc(firestore, 'Events', eventId);
                await updateDoc(eventDocRef, { isApproved: true });
                alert("Event approved successfully.");
                fetchEvents(adminSchool); // Refresh the events list after approval
                closeModal(); // Close the modal after approval
            } catch (error) {
                console.error("Error approving event: ", error);
                alert("Error approving event.");
            }
        }
    };
    
    
    // Deny (delete) an event request
    const handleDenyEventRequest = async (eventId) => {
        if (window.confirm("Are you sure you want to deny this event request?")) {
            try {
                await deleteDoc(doc(firestore, "Events", eventId));
                alert("Event request denied.");
                closeModal();
                fetchEvents(adminSchool);
            } catch (error) {
                console.error("Error denying event request: ", error);
                alert("Error denying event request.");
            }
        }
    };

    function openModal(event) {
        setSelectedEvent(event);
        if (event.eventAttendees && event.eventAttendees.length > 0) {
            const firstAttendeeId = event.eventAttendees[0];
            fetchUserDetails(firstAttendeeId);
        } else {
            // Reset posterDetails if no attendees or unable to fetch
            setPosterDetails({ name: 'Unknown', email: 'N/A' });
        }
    }
    
    // Function to close the modal
    const closeModal = () => {
        setSelectedEvent(null);
    };

    const [eventsPerPage, setEventsPerPage] = useState(8); // Start with a default value

        const updateEventsPerPage = () => {
        const screenHeight = window.innerHeight;
        if (screenHeight >= 1200) {
            setEventsPerPage(16); // 2 columns * 8 rows for larger screens
        } else if (screenHeight >= 1000) {
            setEventsPerPage(12); // 2 columns * 6 rows
        } else if (screenHeight >= 800) {
            setEventsPerPage(8); // 2 columns * 4 rows
        } else {
            setEventsPerPage(4); // 2 columns * 2 rows for smaller screens
        }
        };

        useEffect(() => {
        updateEventsPerPage();
        window.addEventListener('resize', updateEventsPerPage);
        return () => window.removeEventListener('resize', updateEventsPerPage);
        }, []);

    
    const indexOfLastActiveEvent = currentActiveEventPage * eventsPerPage;
    const indexOfFirstActiveEvent = indexOfLastActiveEvent - eventsPerPage;
    const currentActiveEvents = events.slice(indexOfFirstActiveEvent, indexOfLastActiveEvent);

    // Pagination logic for event requests
    const indexOfLastEventRequest = currentEventRequestPage * eventsPerPage;
    const indexOfFirstEventRequest = indexOfLastEventRequest - eventsPerPage;
    const currentEventRequests = eventRequests.slice(indexOfFirstEventRequest, indexOfLastEventRequest);

    const totalActiveEventPages = Math.ceil(events.length / eventsPerPage);
    const totalEventRequestPages = Math.ceil(eventRequests.length / eventsPerPage);

    ;

    return (
        <div className="bulletin-page">

<div className="tab-switches">
    <button 
        onClick={() => setActiveTab('addEvents')} 
        className={activeTab === 'addEvents' ? 'active' : ''}>
        Add Events
    </button>
    <button 
        onClick={() => setActiveTab('eventRequests')} 
        className={activeTab === 'eventRequests' ? 'active' : ''}>
        Event Requests
    </button>
    <button 
        onClick={() => setActiveTab('activeEvents')} 
        className={activeTab === 'activeEvents' ? 'active' : ''}>
        Active Events
    </button>
</div>


            {activeTab === 'addEvents' && (

<div className="event_form add-event-section">
    <div className="event_inner">
        <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                <h2>Create a New Event</h2>
                <p>Fill in the details below to add a new event to the bulletin board.</p>
            </div>
            <form onSubmit={handleAddEvent} className="event-form" data-aos="fade-up" data-aos-duration={1500}>
                <div className="row">


                    <div className="col-md-6 form-group">
                        <input type="text" name="name" value={newEvent.name} onChange={handleInputChange} className="form-control" placeholder="Event Name *" required />
                    </div>

                    <div className="col-md-6 form-group">
                    <input type="file" id="eventImage" name="image" accept="image/*" onChange={handleImageChange} className="form-control" required />
                    </div>

                    <div className="col-md-6 form-group">
                        <input type="text" name="location" value={newEvent.location} onChange={handleInputChange} className="form-control" placeholder="Location *" required />
                    </div>


                    <div className="col-md-6 form-group">
                        <input type="text" name="websiteURL" value={newEvent.websiteURL} onChange={handleInputChange} className="form-control" placeholder="Website URL"/>
                    </div>

                    <div className="col-md-12 form-group">
                            <select name="category" value={newEvent.category} onChange={handleInputChange} className="form-control" required>
                                <option value="">Select Category</option>
                                <option value="Social">Social</option>
                                <option value="Sports">Sports</option>
                                <option value="Professional">Professional</option>
                            </select>
                        </div>

                    <div className="col-md-12 form-group">
                        <textarea className="form-control" name="description" value={newEvent.description} onChange={handleInputChange} placeholder="Description"></textarea>
                    </div>

                    <div className="col-md-6 form-group">
                        <input type="date" name="date" value={newEvent.date} onChange={handleInputChange} className="form-control" required />
                    </div>
                    <div className="col-md-6 form-group">
                        <input type="time" name="time" value={newEvent.time} onChange={handleInputChange} className="form-control" required />
                    </div>

                    <div className="col-md-12 text-right">
                        <button type="submit" className="btn purple_btn">Submit Event</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


)}


{activeTab === 'eventRequests' && (
            <div className="event-requests-section">
                <h2>Event Requests ({eventRequests.length})</h2>
                {eventRequests.length > 0 ? (
                    <div className="events-list">
                           {currentEventRequests.map(event => (
                                 <div key={event.id} className="event-item" onClick={() => openModal(event)}>
                                 <div className="event-details">
                              <img src={event.imageURL || logo} alt={event.name || "Event Image"} className="event-image" />
                               <div className="event-info">
                                 <h3 className="event-name">{event.name}</h3>
                                  <p className="event-date-time">{event.date} at {event.time}</p>
                               </div>
                             </div>
                       </div>
                         ))}
                </div>
                  ) : (
                    <p>No current event requests.</p> 
                )}
                
             {totalEventRequestPages > 1 && (
                    <div className="pagination">
                         {Array.from({ length: totalEventRequestPages }, (_, i) => (
                           <button
                                key={i}
                                onClick={() => setCurrentEventRequestPage(i + 1)}
                                className={currentEventRequestPage === i + 1 ? 'active' : ''}
                              >
                                 {i + 1}
                         </button>
                       ))}
                     </div>
                     )}
            </div>
)}


{activeTab === 'activeEvents' && (
            <div className="active-events-section">
                    <h2>Active Events ({events.length})</h2>
                    {events.length > 0 ? (
                    <div className="events-list">
                       {currentActiveEvents.map(event => (
                          <div key={event.id} className="event-item" onClick={() => openModal(event)}>
                              <div className="event-details">
                                   <img src={event.imageURL || logo} alt={event.name || "Event Image"} className="event-image" />
                                     <div className="event-info">
                                        <h3 className="event-name">{event.name}</h3>
                                    <p className="event-date-time">{event.date} at {event.time}</p>
                                        </div>
                                </div>
                      </div>
                      ))}
                 </div>
                  ) : (
                    <p>No current events.</p> 
                )}

                    {totalActiveEventPages > 1 && (
                       <div className="pagination">
                          {Array.from({ length: totalActiveEventPages }, (_, i) => (
                             <button
                                key={i}
                                 onClick={() => setCurrentActiveEventPage(i + 1)}
                                 className={currentActiveEventPage === i + 1 ? 'active' : ''}
                                   >
                              {i + 1}
                           </button>
                             ))}
                        </div>
                         )}
            </div>
)}


            {/* Modal for displaying detailed event information */}
            <Modal
    isOpen={selectedEvent !== null}
    onRequestClose={closeModal}
    style={customModalStyles}
>
    {selectedEvent && (
        <div className="event-modal-content">
            <h2>{selectedEvent.name}</h2>
            <p> {selectedEvent.description}</p>
            <p><strong>Location:</strong> {selectedEvent.location}</p>
            <p><strong>Date:</strong> {selectedEvent.date}</p>
            <p><strong>Time:</strong> {selectedEvent.time}</p>
            <p><strong>Category:</strong> {selectedEvent.category}</p>
            {selectedEvent.websiteURL && (
                <p><strong>Website:</strong> <a href={selectedEvent.websiteURL} target="_blank" rel="noopener noreferrer">{selectedEvent.websiteURL}</a></p>
            )}

            {selectedEvent.isApproved ? (
                     <p></p>
                ) : (
                    <p><strong>Requested by:</strong> {posterDetails.name} ({posterDetails.email})</p>
                )}

         <div className="modal-buttons">
            <button onClick={closeModal} className="close-btn">Close</button>

                {selectedEvent.isApproved ? (
                    <button onClick={() => handleDeleteEvent(selectedEvent.id)} className="remove-btn">Remove Event</button>
                ) : (
                    <>
                        <button onClick={() => handleApproveEventRequest(selectedEvent.id)} className="approve-btn">Approve</button>
                        <button onClick={() => handleDenyEventRequest(selectedEvent.id)} className="remove-btn">Deny</button>
                    </>
                )}
         </div>
        </div>
    )}
</Modal>


        </div>
    );
};

export default BulletinPage;
