import React, { useState } from 'react';
import Modal from 'react-modal';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';
import './Login.css';


Modal.setAppElement('#root');

const Login = ({ showModal, toggleModal }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
{/*
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log('Login successful, navigating to admin');
            navigate('/admin');
            toggleModal();
        } catch (error) {
            alert(error.message);
        }
    }; */}

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            // Fetch additional user data from Firestore
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists() && userDoc.data().role === 'admin') {
                console.log('Admin login successful, navigating to admin');
                navigate('/admin');
            } else {
                console.log('Not an admin or no additional user data');
                // Handle non-admin user or missing data
            }
            toggleModal();
        } catch (error) {
            alert(error.message);
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault(); // Prevent form submission if it's a button inside a form

        if (!email) {
            alert("Please enter your email address.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent. Please check your inbox.");
        } catch (error) {
            console.error("Failed to send password reset email:", error);
            alert("Failed to send password reset email. Please try again later.");
        }
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={toggleModal}
            contentLabel="Login Modal"
            className="modal-content"
            overlayClassName="modal-overlay"
        >
            <span onClick={toggleModal} className="close" title="Close Modal">&times;</span>

            {/* Modal Content */}
            <form onSubmit={handleLogin}>
                <div className="login-container">
                    <label htmlFor="email"><b>Email</b></label>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Enter Email" 
                        required 
                    />

                    <label htmlFor="password"><b>Password</b></label>
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Enter Password" 
                        required 
                    />

                    <button className = "login-button" type="submit">Login</button>
                    <button onClick={handleForgotPassword} className="forgot-password-button">  Forgot Password? </button>
                
                </div>
            </form>
        </Modal>
    );
};

export default Login;
