import React, { useEffect, useState, useRef  } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/images/logo.png'

import Login from '../../Login';



function Main() {

    const [headerScroll, setheaderScroll] = useState(false)
    const [mobile, setmobile] = useState()
    const [Page, setPage] = useState()
    const [lastScrollY, setLastScrollY] = useState(window.pageYOffset);
    const [headerVisible, setHeaderVisible] = useState(true);

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const toggleLoginModal = () => setIsLoginModalOpen(!isLoginModalOpen);

    const location = useLocation(); // Track location changes

    const closeNavbar = () => {
        setmobile(false);
      };

    useEffect(() => {

        const fixedHeader = () => {
            if (window.pageYOffset > 150) {
                setheaderScroll(true)
            } else {
                setheaderScroll(false)
            }
        }
        window.addEventListener('scroll', fixedHeader)

    }, [])

     useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.pageYOffset;
            setheaderScroll(currentScrollY > 150);
            setHeaderVisible(lastScrollY >= currentScrollY || currentScrollY < 10);
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {/* <header className={headerScroll ? "fixed fix_style" : "fixed"} > */}
            {/* <header className={`${headerScroll ? "fixed fix_style" : "fixed"} ${headerVisible ? "fade-in" : "fade-out"}`}> */}
            <header className={`${headerScroll ? "fixed fix_style" : "fixed"} ${headerVisible ? "fade-in" : "fade-out"}`}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt="Logo" />
                        </Link>
                        <button className={`navbar-toggler ${mobile && "collapsed"}`} onClick={() => setmobile(mobile === true ? false : true)} type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <div className={`toggle-wrap ${mobile && "active"}`} onClick={() => setmobile(mobile === true ? false : true)}>
                                <span className="toggle-bar"></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">
                                        Home
                                    </Link>
                                </li>
                              

                                <li className="nav-item active">
                                     <a className="nav-link" href="#key-features">Features</a>
                                    </li>

                                    <li className="nav-item active">
                                     <a className="nav-link" href="/home#testimonials">Testimonials</a>
                                    </li>

                                    
                                    <li className="nav-item active">
                                     <a className="nav-link" href="#faq">FAQ's</a>
                                    </li>

                                    <li className="nav-item">
                                    <Link className="nav-link" to="/about">
                                        About us
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">
                                        Contact
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <div className="btn_block">
                                            <button onClick={toggleLoginModal}>Admin Login</button>
                                    </div>
                                </li>

                            </ul>
                        </div>

                        {mobile &&
                            <>
                                <div className={`navbar-collapse collapse ${mobile && "show"}`} id="navbarSupportedContent" style={{}} >
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <Link className="nav-link" to="/">
                                                Home
                                            </Link>
                                        </li>

                                        <li className="nav-item active">
                                     <a className="nav-link" href="#key-features"onClick={closeNavbar}>Features</a>
                                    </li>

                                    <li className="nav-item active">
                                     <a className="nav-link" href="#testimonials"onClick={closeNavbar}>Testimonials</a>
                                    </li>

                                    
                                    {/* <li className="nav-item active">
                                     <a className="nav-link" href="#faq">FAQ's</a>
                                    </li> */}

                                        <li className="nav-item active">
                                            <a className="nav-link" href="#faq" onClick={closeNavbar}>FAQ's</a>
                                        </li>

                                        <li className="nav-item">
                                            <Link className="nav-link" to="/about">
                                                About us
                                            </Link>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contact">
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                    </nav>
                </div >
            </header>
            <Login showModal={isLoginModalOpen} toggleModal={toggleLoginModal} />
        </>
    )
}

export default Main