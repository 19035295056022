import React, { useState } from 'react';
import { useNavigate, navigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './CustomerInfoPage.css';

const stripePromise = loadStripe('pk_live_51OsG8fRvvv49RsIdZci9uRVEqY2GLSZ5EoHJMiO8UNHLK0bYFiqK7rJNztRz33EutlLBfRryZqXGTkHzjqHHoJSM00Yea4z4rK'); // Replace with your actual publishable key

const CustomerInfoPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        phone: ''
    });

    const navigate = useNavigate();
    const stripe = useStripe();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Create a customer in Stripe
        const customer = await fetch('https://us-central1-collegiate-43b7c.cloudfunctions.net/createCustomer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(response => response.json());
        if (customer.error) {
            console.error(customer.error.message);
            return;
        }

        const customerId = customer.id;

        // Redirect to the next page
        navigate(`/payment-method?customerId=${customerId}`);
    };

    return (
        <div className="customer-info-container">
            <div className="customer-info-card">
                    <div className="customer-info-left-panel">
                        <div>
                               Create Your Customer Profile
                        </div>
                       <div className="customer-info-powered-by">
                         Powered by Stripe
                       </div>
                 </div>
                <div className="customer-info-right-panel">
                    <label>Customer Information: </label>
                    <form onSubmit={handleSubmit}>
                        <input className="customer-info-input" type="text" name="name" placeholder="Institution Name" value={formData.name} onChange={handleChange} />
                        <input className="customer-info-input" type="email" name="email" placeholder="Email Adress" value={formData.email} onChange={handleChange} />
                        <input className="customer-info-input" type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} />
                        <input className="customer-info-input" type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} />
                        <input className="customer-info-input" type="text" name="state" placeholder="State" value={formData.state} onChange={handleChange} />
                        <input className="customer-info-input" type="text" name="postalCode" placeholder="Postal Code" value={formData.postalCode} onChange={handleChange} />
                        <input className="customer-info-input" type="text" name="country" placeholder="Country" value={formData.country} onChange={handleChange} />
                        <input className="customer-info-input"type="text" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} />
                        <button className="customer-info-button" type="submit">Next</button>
                    </form>
                </div>
            </div>
        </div>
    );
    
};

export default CustomerInfoPage;
