import React, { useEffect } from 'react'
import Header from '../Header/Main'
import Banner from '../Menhome/Banner'
import Keyfeature from '../Menhome/Keyfeature'
import Aboutus from '../Menhome/Aboutus'
import Contactform from '../Mencontact/Contactform'
import TextList from '../Menhome/TextList'
import Service from '../Menhome/Service'
import HowWork from '../Menhome/HowWork'
import Review from '../Menhome/Review'
import Clients from '../Menhome/Clients'
import Pricing from '../Menhome/Pricing'
import Interface from '../Menhome/Interface'
import Text from '../Menhome/Text'
import Blog from '../Menhome/Blog'
import Footer from '../Footer/Main'
import Faq from '../Menabout/Faq'
import Aos from 'aos'

function Main() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);


    return (
        <>
            <Header />
            
            {/* <Keyfeature /> */}


            <div className="page_wrapper">
            <Banner />
            
            <div id="key-features">
                    <Keyfeature />
             </div>

                <Aboutus />
                 <Service />

                 <div id="testimonials">
                <Review />
                </div>
                <div id="faq">
                <Faq />
                </div>

                <Contactform/>
                <Footer />
            </div>
        </>
    )
}

export default Main