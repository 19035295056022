import React, { useEffect, useState } from 'react';
import {auth, db } from '../firebase';
import { firestore } from '../firebase';
import { collection, addDoc, query, where, getDocs, getDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import './BulletinPage.css';
import Modal from 'react-modal';
import './Resources.css';

Modal.setAppElement('#root');

const customModalStyles = {
  content: {
      maxWidth: '50%', 
      maxHeight: '50vh', 
      margin: 'auto', // Center the modal
      padding: '20px',
      borderRadius: '10px',
      position: 'relative', // Ensure it's centered
  },
  overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent overlay
  },
};


function Resources() {
  const [selectedResource, setSelectedResource] = useState(null); // State to track the selected event for the modal
  const [resources, setResources] = useState([]);
  const [adminSchool, setAdminSchool] = useState('');
  const [resource, setResource] = useState({
    name: '',
    description: '',
    location: '',
    hours: '',
    phoneNumber: '',
    email: '',
    websiteURL: '',
  });

  const [resourcesPerPage, setResourcesPerPage] = useState(8); // Initial default value

const updateResourcesPerPage = () => {
  const screenHeight = window.innerHeight;
  if (screenHeight >= 1200) {
      setResourcesPerPage(16); // 2 columns * 8 rows
  } else if (screenHeight >= 1000) {
      setResourcesPerPage(12); // 2 columns * 6 rows
  } else if (screenHeight >= 800) {
      setResourcesPerPage(8); // 2 columns * 4 rows
  } else {
      setResourcesPerPage(4); // 2 columns * 2 rows for smaller screens
  }
};

useEffect(() => {
  // Update resources per page based on initial screen height
  updateResourcesPerPage();

  // Add resize event listener to update resources per page on screen resize
  window.addEventListener('resize', updateResourcesPerPage);

  // Cleanup the event listener on component unmount
  return () => window.removeEventListener('resize', updateResourcesPerPage);
}, []);



  const [activeTab, setActiveTab] = useState('add-event-section');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResource({ ...resource, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resourceToSave = {
        ...resource,
        school: adminSchool // Add the school information
    };
    try {
        await addDoc(collection(db, "campusResources"), resourceToSave);
        alert("Resource added successfully!");
        setResource({ // Clear form
            name: '',
            description: '',
            location: '',
            hours: '',
            phoneNumber: '',
            email: '',
            websiteURL: '',
            school: ''
        });
    } catch (error) {
        console.error("Error adding document: ", error);
        alert("Error adding resource!");
    }
};


  useEffect(() => {
    const fetchAdminSchool = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          setAdminSchool(userDoc.data().school);
        }
      }
    };

    fetchAdminSchool();
  }, []);

  useEffect(() => {
    if (adminSchool) {
      fetchResources(adminSchool);
    }
  }, [adminSchool]);

  const fetchResources = async (school) => {
    try {
      const q = query(collection(db, "campusResources"), where("school", "==", school));
      const querySnapshot = await getDocs(q);
      const resourcesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setResources(resourcesData);
    } catch (error) {
      console.error("Error fetching resources: ", error);
    }
  };
  
  const handleDeleteResource= async (resourceId) => {
    if (window.confirm("Are you sure you want to delete this resource?")) {
        try {
            await deleteDoc(doc(firestore, "campusResources", resourceId));
            alert("Resource deleted successfully.");
            closeModal(); 
            fetchResources(); 

        } catch (error) {
            console.error("Error deleting resource: ", error);
            alert("Error deleting resource.");
        }
    }
};

  const openModal = (resource) => {
    setSelectedResource(resource);
};

// Function to close the modal
const closeModal = () => {
    setSelectedResource(null);
};

const [currentResourcePage, setCurrentResourcePage] = useState(1);

const totalResourcePages = Math.ceil(resources.length / resourcesPerPage);


const indexOfLastResource = currentResourcePage * resourcesPerPage;
const indexOfFirstResource = indexOfLastResource - resourcesPerPage;
const currentResources = resources.slice(indexOfFirstResource, indexOfLastResource);

  return (

<div className="resource-page">

<div className="tab-switches">
    <button 
        onClick={() => setActiveTab('add-event-section')} 
        className={activeTab === 'add-event-section' ? 'active' : ''}>
        Add Campus Resource
    </button>
    <button 
        onClick={() => setActiveTab('resource-section')} 
        className={activeTab === 'resource-section' ? 'active' : ''}>
        Resources
    </button>
</div>
{activeTab === 'add-event-section' && (
  <div className="event_form add-event-section">
  <div className="event_inner">
    <div className="container">
      <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
        <h2>Add Campus Resource</h2>
        <p>Fill in the details below to add a new campus resource.</p>
      </div>
      <form onSubmit={handleSubmit} className="event-form" data-aos="fade-up" data-aos-duration={1500}>
        <div className="row">
          <div className="col-md-6 form-group">
            <input type="text" name="name" value={resource.name} onChange={handleInputChange} className="form-control" placeholder="Resource Name *" required />
          </div>
          <div className="col-md-6 form-group">
            <input type="text" name="location" value={resource.location} onChange={handleInputChange} className="form-control" placeholder="Location *" required />
          </div>
          <div className="col-md-12 form-group">
            <textarea className="form-control" name="description" value={resource.description} onChange={handleInputChange} placeholder="Description"></textarea>
          </div>
          <div className="col-md-6 form-group">
            <input type="tel" name="phoneNumber" value={resource.phoneNumber} onChange={handleInputChange} className="form-control" placeholder="Phone Number" />
          </div>
          <div className="col-md-6 form-group">
            <input type="email" name="email" value={resource.email} onChange={handleInputChange} className="form-control" placeholder="Email" />
          </div>
          <div className="col-md-6 form-group">
            <input type="text" name="hours" value={resource.hours} onChange={handleInputChange} className="form-control" placeholder="Operating Hours" />
          </div>
          <div className="col-md-6 form-group">
            <input type="url" name="websiteURL" value={resource.websiteURL} onChange={handleInputChange} className="form-control" placeholder="Website URL" />
          </div>
          <div className="col-md-12 text-right">
            <button type="submit" className="btn purple_btn">Add Resource</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

   )}

            {activeTab === 'resource-section' && (
              <div className="resource-section"> 
                <h2>Resources ({currentResources.length})</h2>
                <div className="resource-list">
                  {currentResources.map(resource => (
                    <div key={resource.id} className="resource-item" onClick={() => openModal(resource)}>
                      <h3>{resource.name}</h3>
                    </div>
                  ))}
                </div>
                {/* Pagination Controls */}
                <div className="pagination">
                  {Array.from({ length: totalResourcePages }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => setCurrentResourcePage(i + 1)}
                      className={currentResourcePage === i + 1 ? 'active' : ''}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>
              </div>
            )}


<Modal
    isOpen={selectedResource !== null}
    onRequestClose={closeModal}
    style={customModalStyles} // Ensure this variable contains your desired CSS styles
>
    {selectedResource && (
        <div className="resource-modal-content">
            <h2>{selectedResource.name}</h2>
            <p><strong>Description:</strong> {selectedResource.description}</p>
            <p><strong>Location:</strong> {selectedResource.location}</p>
            <p><strong>Operating Hours:</strong> {selectedResource.hours}</p>
            {selectedResource.websiteURL && (
                <p><strong>Website:</strong> <a href={selectedResource.websiteURL} target="_blank" rel="noopener noreferrer">{selectedResource.websiteURL}</a></p>
            )}
            {selectedResource.phoneNumber && (
                <p><strong>Phone Number:</strong> {selectedResource.phoneNumber}</p>
            )}
            {selectedResource.email && (
                <p><strong>Email:</strong> {selectedResource.email}</p>
            )}
            <div className="modal-buttons">
                <button onClick={closeModal} className="close-btn">Close</button>
                <button onClick={() => handleDeleteResource(selectedResource.id)} className="delete-resource-btn">Remove Resource</button>
            </div>
        </div>
    )}
</Modal>


</div>
  );

}

export default Resources;
