import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/ft_logo.png'
import googleplay from '../../assets/images/googleplay.png'
import appstorebtn from '../../assets/images/appstorebtn.png'

function Main() {
    return (
        <>
            <footer className="white_text" data-aos="fade-in" data-aos-duration={1500}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="logo_side">
                                <div className="logo-text">
                                    <Link to="#">
                                        <h2>Collegiate</h2>
                                    </Link>
                                </div>
                                <ul className="contact_info">
                                    <li>
                                        <Link to="mailto:">info@collegiateapp.co</Link>
                                    </li>
                                </ul>
                                <ul className="social_media">
                                <li>
                                        <Link to="https://www.linkedin.com/company/collegiate-app/">
                                            <i className="icofont-linkedin" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.instagram.com/collegiate_app">
                                            <i className="icofont-instagram" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="icofont-facebook" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="ft_inner">
                            <div className="copy_text">
                                <p>©2024 On Campus Inc. All rights reserved.</p>
                            </div>
                            <ul className="links">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About us</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact us</Link>
                                </li>
                                <li>
                                    <Link to="/support">Support</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Main