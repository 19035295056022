import React from 'react'
import {BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Home from '../Components/Home/Main'
import About from '../Components/About/Main'
import BlogList from '../Components/Pages/BlogList/Main'
import BlogDetails from '../Components/Pages/BlogDetails/Main'
import SignIn from '../Components/Pages/Sign In/Main'
import SignUp from '../Components/Pages/Sign Up/Main'
import Pricing from '../Components/Pricing/Main'
import Review from '../Components/Reviews/Main'
import Contact from '../Components/Contact/Main'
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import Support from '../Components/Support/Main'
import Demo from '../Components/Demo/Main'

import AdminPage from '../admin/AdminPage';
import BulletinPage from '../admin/BulletinPage';
import ClubsPage from '../admin/ClubsPage';
import ChatsPagePage from '../admin/ChatsPage';
import StudyGroupPage from '../admin/StudyGroupPage';
import WrappedForm from '../admin/WrappedForm';
import Layout from '../Layout';
import Resources from '../admin/Resources';
import PrivateRoute from '../admin/PrivateRoute';
import { AuthProvider } from '../admin/AuthContext';
import Login from '../Login';
import Signup from '../Signup';
import ChatsPage from '../admin/ChatsPage'
import CustomerInfoPage from '../payment/CustomerInfoPage'
import PaymentMethodPage from '../payment/PaymentMethodPage'
//import WrappedForm from '../admin/WrappedForm'

const stripePromise = loadStripe('pk_live_51OsG8fRvvv49RsIdZci9uRVEqY2GLSZ5EoHJMiO8UNHLK0bYFiqK7rJNztRz33EutlLBfRryZqXGTkHzjqHHoJSM00Yea4z4rK'); // Replace with your actual publishable key

const Routing = () => {
{/*
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog-detail" element={<BlogDetails />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/reviews" element={<Review />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  )
}
*/}
return (
      <Routes>
        {/* Redirect base URL to /home */}
        <Route path="/" element={<Navigate replace to="/home" />} />

        {/* Public Routes */}
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
{/*         
        <Route path="/blog-detail" element={<BlogDetails />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/reviews" element={<Review />} /> */}

        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
        <Route path="/demo" element={<Demo />} />

        <Route path="customer-info" element={<Elements stripe={stripePromise}><CustomerInfoPage /></Elements>} />
        <Route path="payment-method" element={<Elements stripe={stripePromise}><PaymentMethodPage /></Elements>} />




        {/* Authentication Routes */}
        <Route path="/home" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* Protected Routes - Admin */}
        <Route path="/admin" element={<PrivateRoute><Layout /></PrivateRoute>}>
          <Route index element={<BulletinPage />} />
          <Route path="bulletin" element={<BulletinPage />} />
          <Route path="clubs" element={<ClubsPage />} />
          <Route path="resources" element={<Resources/>} />
          <Route path="chats" element={<ChatsPage/>} />
          <Route path="study-groups" element={<StudyGroupPage />} />

          {/* Add additional admin routes here */}
        </Route>

        {/* Fallback Route - Redirects to /home for any undefined routes */}
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Routes>
);
};

export default Routing