// StudyGroupsPage.js
import React, { useEffect, useState } from 'react';
import {auth, db } from '../firebase';
import { collection, getDocs, query, where, doc, getDoc, deleteDoc} from 'firebase/firestore';
import Modal from 'react-modal';
import './StudyGroupPage.css';

Modal.setAppElement('#root');

const customModalStyles = {
  content: {
    maxWidth: '50%',
    maxHeight: '50vh',
    margin: 'auto',
    padding: '20px',
    borderRadius: '10px',
    position: 'relative',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};

function StudyGroupsPage() {
  const [selectedStudyGroup, setSelectedStudyGroup] = useState(null);
  const [studyGroups, setStudyGroups] = useState([]);
  const [adminSchool, setAdminSchool] = useState('');
  const [currentStudyGroupPage, setCurrentStudyGroupPage] = useState(1);

  const [creatorDetails, setCreatorDetails] = useState({ name: '', email: '' });


  useEffect(() => {
    const fetchAdminSchool = async () => {
        if (auth.currentUser) {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                setAdminSchool(userDoc.data().school);
            }
        }
    };

    fetchAdminSchool();
}, []);

useEffect(() => {
    if (adminSchool) {
        fetchStudyGroups(adminSchool);
    }
}, [adminSchool]);

const fetchStudyGroups = async (school) => {
    try {
      const q = query(collection(db, "studyGroups"), where("school", "==", school));
      const querySnapshot = await getDocs(q);
      const studyGroupsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setStudyGroups(studyGroupsData);
    } catch (error) {
      console.error("Error fetching study groups: ", error);
    }
  };

  const removeStudyGroup = async (studyGroupId) => {
    const confirmation = window.confirm("Are you sure you want to delete this study group?");
    if (confirmation) {
      try {
        await deleteDoc(doc(db, "studyGroups", studyGroupId));
        alert("Study group deleted successfully.");
        closeModal(); // Close the modal after deletion
        fetchStudyGroups(adminSchool); // Refresh the study groups list
      } catch (error) {
        console.error("Error deleting study group: ", error);
        alert("Error deleting study group.");
      }
    }
  };
  
  
  const openModal = (studyGroup) => {
    setSelectedStudyGroup(studyGroup);

    if (studyGroup.creator && studyGroup.creator.id) {
        fetchUserDetails(studyGroup.creator.id);
      } else {
        // Reset creatorDetails if no creator or unable to fetch
        setCreatorDetails({ name: 'Unknown', email: 'N/A' });
      }

  };

  const closeModal = () => {
    setSelectedStudyGroup(null);
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId); // Adjust the path as necessary
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCreatorDetails({ name: userData.name, email: userData.email });
      } else {
        setCreatorDetails({ name: 'Unknown', email: 'N/A' });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      setCreatorDetails({ name: 'Error', email: 'Error' });
    }
  };
  

  const [studyGroupsPerPage, setStudyGroupsPerPage] = useState(8); // Initial default value

  const updateStudyGroupsPerPage = () => {
    const screenHeight = window.innerHeight;
    if (screenHeight >= 1200) {
      setStudyGroupsPerPage(16); // 2 columns * 8 rows
    } else if (screenHeight >= 1000) {
      setStudyGroupsPerPage(12); // 2 columns * 6 rows
    } else if (screenHeight >= 800) {
      setStudyGroupsPerPage(8); // 2 columns * 4 rows
    } else {
      setStudyGroupsPerPage(4); // 2 columns * 2 rows for smaller screens
    }
  };

  useEffect(() => {
    // Update study groups per page based on initial screen height
    updateStudyGroupsPerPage();

    // Add resize event listener to update study groups per page on screen resize
    window.addEventListener('resize', updateStudyGroupsPerPage);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updateStudyGroupsPerPage);
  }, []);



  const indexOfLastStudyGroup = currentStudyGroupPage * studyGroupsPerPage;
  const indexOfFirstStudyGroup = indexOfLastStudyGroup - studyGroupsPerPage;
  const currentStudyGroups = studyGroups.slice(indexOfFirstStudyGroup, indexOfLastStudyGroup);
  const totalStudyGroupPages = Math.ceil(studyGroups.length / studyGroupsPerPage);



  return (
    <div className="study-group-page">
      <h2>Study Groups ({currentStudyGroups.length})</h2>
      <div className="study-group-list">
      {currentStudyGroups.map(studyGroup => (
        <div key={studyGroup.id} className="study-group-item" onClick={() => openModal(studyGroup)}>
            <h3>{studyGroup.className}</h3> {/* Verify this matches the field name */}
            <p>{studyGroup.professorName}</p> {/* Verify this matches the field name */}
        </div>
        ))}
      </div>
      <div className="pagination">
        {Array.from({ length: totalStudyGroupPages }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentStudyGroupPage(i + 1)}
            className={currentStudyGroupPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>

      <Modal
        isOpen={selectedStudyGroup !== null}
        onRequestClose={closeModal}
        style={customModalStyles}
      >
        {selectedStudyGroup && (
          <div className="study-group-modal-content">
            <h2>{selectedStudyGroup.className}</h2>
            <p><strong>Professor:</strong> {selectedStudyGroup.professorName}</p>
            <p><strong>Meeting Time:</strong> {selectedStudyGroup.dateTime ? new Date(selectedStudyGroup.dateTime.seconds * 1000).toLocaleString() : 'Not provided'}</p>
            <p><strong>Covering:</strong> {selectedStudyGroup.description}</p>
            <p><strong>Created by:</strong> {creatorDetails.name} ({creatorDetails.email})</p>
            
            <div className="modal-buttons">
              <button onClick={closeModal} className="close-btn">Close</button>
              <button onClick={() => removeStudyGroup(selectedStudyGroup.id)} className="delete-study-btn">Delete Study Group</button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default StudyGroupsPage;
