import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut as firebaseSignOut, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase'; // Make sure this path is correct to your Firebase config file

// Create the context
const AuthContext = createContext();

// Create the custom hook for using the AuthContext

    //export const useAuth = () => useContext(AuthContext);

    export const useAuth = () => {
        const context = useContext(AuthContext);
        console.log("Current user in useAuth:", context.currentUser);
        return context;
    };
    
// Provide the AuthProvider
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        console.log("Auth State Changed, user:", user);
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Function to sign in
  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  // Function to sign out
  const signOut = () => {
    return firebaseSignOut(auth);
  };

  // Function to sign up (register)
  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  // The value to be passed to consuming components
  const value = {
    currentUser,
    signIn,
    signOut,
    signUp,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
