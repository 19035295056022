// ClubsPage.js
import React, { useEffect, useState } from 'react';
import {auth, db } from '../firebase';
import { firestore } from '../firebase';
import { collection, query, where, getDocs, getDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import './ClubsPage.css';

import Modal from 'react-modal';

Modal.setAppElement('#root');

const customModalStyles = {
    content: {
        maxWidth: '50%', 
        maxHeight: '50%', 
        margin: 'auto', // Center the modal
        padding: '20px',
        borderRadius: '10px',
        position: 'relative', // Ensure it's centered
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent overlay
    },
};

const ClubsPage = () => {
    const [clubRequests, setClubRequests] = useState([]);
    const [activeClubs, setActiveClubs] = useState([]);
    const [selectedClub, setSelectedClub] = useState(null); // State to track the selected club for the modal
    const [currentPage, setCurrentPage] = useState(1);
    const [adminSchool, setAdminSchool] = useState('');
    const [posterDetails, setPosterDetails] = useState({ name: '', email: '' });

    const [activeTab, setActiveTab] = useState('clubs-column-requests');

useEffect(() => {
    const fetchAdminSchool = async () => {
        if (auth.currentUser) {
            const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
            if (userDoc.exists()) {
                setAdminSchool(userDoc.data().school);
            }
        }
    };

    fetchAdminSchool();
}, []);

useEffect(() => {
    if (adminSchool) {
        fetchClubRequests(adminSchool);
        fetchActiveClubs(adminSchool);
    }
}, [adminSchool]);

const fetchClubRequests = async (school) => {
    const q = query(collection(db, "clubs"), where("isApproved", "==", false), where("school", "==", school));
    const querySnapshot = await getDocs(q);
    setClubRequests(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
};

const fetchActiveClubs = async (school) => {
  const q = query(collection(db, "clubs"), where("isApproved", "==", true), where("school", "==", school));
  const querySnapshot = await getDocs(q);
  const sortedClubs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
  .sort((a, b) => a.name.localeCompare(b.name)); // Sort by club name alphabetically
setActiveClubs(sortedClubs);
};

    // const approveClub = async (clubId, school) => {
    //     const clubRef = doc(db, "clubs", clubId);
    //     await updateDoc(clubRef, { isApproved: true });
    //     fetchClubRequests(school);
    //     fetchActiveClubs(school);

    //     closeModal();
    // };

    // const declineClub = async (clubId, school) => {
    //     await deleteDoc(doc(db, "clubs", clubId));
    //     fetchClubRequests(school);

    //     closeModal();
    // };

    const approveClub = async (clubId, school) => {
        if (window.confirm("Are you sure you want to approve this club?")) {
            const clubRef = doc(db, "clubs", clubId);
            try {
                await updateDoc(clubRef, { isApproved: true });
                alert("Club approved successfully.");
                fetchClubRequests(school);
                fetchActiveClubs(school);
            } catch (error) {
                console.error("Error approving club: ", error);
                alert("Error approving club.");
            } finally {
                closeModal(); // Ensure the modal is closed regardless of success or error
            }
        }
    };
    
    const declineClub = async (clubId, school) => {
        if (window.confirm("Are you sure you want to decline this club?")) {
            try {
                await deleteDoc(doc(db, "clubs", clubId));
                alert("Club declined successfully.");
                fetchClubRequests(school);
            } catch (error) {
                console.error("Error declining club: ", error);
                alert("Error declining club.");
            } finally {
                closeModal(); // Ensure the modal is closed regardless of success or error
            }
        }
    };
    

    const handleDeleteClub= async (clubId) => {
        if (window.confirm("Are you sure you want to delete this club?")) {
            try {
                await deleteDoc(doc(firestore, "clubs", clubId));
                alert("Club deleted successfully.");
                closeModal(); // Close the modal after deletion
                fetchActiveClubs(); // Refresh the events list
            } catch (error) {
                console.error("Error deleting club: ", error);
                alert("Error deleting club.");
            }
        }
    };
    
    const ClubInfo = ({ club }) => (
      <div className="club-card" onClick={() => openModal(club)}>
          {club.imageUrl && (
              <img src={club.imageUrl} alt={club.name} className="club-image" />
          )}
          <div className="club-info">
              <h2 className="club-name">{club.name}</h2>
          </div>
      </div>
  );

  const fetchUserDetails = async (userId) => {
    try {
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            const userData = userSnap.data();
            setPosterDetails({ name: userData.name, email: userData.email });
        } else {
            setPosterDetails({ name: 'Unknown', email: 'N/A' });
        }
    } catch (error) {
        console.error("Error fetching user details: ", error);
        // Optionally, reset posterDetails or handle the error as appropriate
        setPosterDetails({ name: 'Error', email: 'Error' });
    }
};
  
const openModal = (club) => {
    setSelectedClub(club);
    if (club.creator && club.creator.id) {
        fetchUserDetails(club.creator.id);
    } else {
        // Reset posterDetails if no creator or unable to fetch
        setPosterDetails({ name: 'Unknown', email: 'N/A' });
    }
};

  // Function to close the modal
  const closeModal = () => {
      setSelectedClub(null);
  };
  const [clubsPerPage, setClubsPerPage] = useState(16); // Start with the default value that matches your requirement

  const updateClubsPerPage = () => {
    const screenHeight = window.innerHeight;
    if (screenHeight >= 1200) {
      setClubsPerPage(16); // 2 columns * 8 rows for larger screens
    } else if (screenHeight >= 1000) {
      setClubsPerPage(12); // 2 columns * 6 rows
    } else if (screenHeight >= 800) {
      setClubsPerPage(8); // 2 columns * 4 rows
    } else {
      setClubsPerPage(4); // 2 columns * 2 rows for smaller screens
    }
  };
  
  useEffect(() => {
    updateClubsPerPage();
    window.addEventListener('resize', updateClubsPerPage);
    return () => window.removeEventListener('resize', updateClubsPerPage);
  }, []);
  
  const [currentClubPage, setCurrentClubPage] = useState(1);
  const totalClubPages = Math.ceil(activeClubs.length / clubsPerPage);
  
  const indexOfLastClub = currentClubPage * clubsPerPage;
  const indexOfFirstClub = indexOfLastClub - clubsPerPage;
  const currentActiveClubs = activeClubs.slice(indexOfFirstClub, indexOfLastClub);
  
const indexOfLastClubRequest = currentClubPage * clubsPerPage;
const indexOfFirstClubRequest = indexOfLastClubRequest - clubsPerPage;
const currentClubRequests = clubRequests.slice(indexOfFirstClubRequest, indexOfLastClubRequest);



    return (
      <div className="clubs-page-container">

            <div className="tab-switches">
                <button 
                    onClick={() => setActiveTab('clubs-column-requests')} 
                    className={activeTab === 'clubs-column-requests' ? 'active' : ''}>
                    Club Requests
                </button>
                <button 
                    onClick={() => setActiveTab('clubs-column')} 
                    className={activeTab === 'clubs-column' ? 'active' : ''}>
                    Active Clubs
                </button>
            </div>

            {activeTab === 'clubs-column-requests' && (
                <div className="clubs-column-requests">
                    <h1 className="club-section-header">Club Requests ({currentClubRequests.length})</h1>
                    <div className="clubs-grid">
                    {currentClubRequests.length > 0 ? (
                        currentClubRequests.map(club => (
                        <ClubInfo key={club.id} club={club} />
                        ))
                    ) : (
                        <p>There are no current pending clubs.</p>
                    )}
                    </div>
                    {Math.ceil(clubRequests.length / clubsPerPage) > 1 && (
                    <div className="pagination">
                        {Array.from({ length: Math.ceil(clubRequests.length / clubsPerPage) }, (_, i) => (
                        <button
                            key={i}
                            onClick={() => setCurrentClubPage(i + 1)}
                            className={currentClubPage === i + 1 ? 'active' : ''}
                        >
                            {i + 1}
                        </button>
                        ))}
                    </div>
                    )}
                </div>
                )}


    {activeTab === 'clubs-column' && (
          <div className="clubs-column">
              <h1 className="club-section-header">Active Clubs ({currentActiveClubs.length})</h1>
              {currentActiveClubs.length > 0 ? (
              <div className="clubs-grid">
                  {currentActiveClubs.map(club => (
                      <ClubInfo key={club.id} club={club} />
                  ))}
              </div>
                                ) : (
                                    <p>There are no current active clubs.</p> 
                                )}
                {totalClubPages > 1 && (
                <div className="pagination">
                    {Array.from({ length: totalClubPages }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => setCurrentClubPage(i + 1)}
                        className={currentClubPage === i + 1 ? 'active' : ''}
                    >
                        {i + 1}
                    </button>
                    ))}
                </div>
                )}
          </div>
        )}
        


        <Modal
    isOpen={selectedClub !== null}
    onRequestClose={closeModal}
    style={customModalStyles}
>
    {selectedClub && (
        <div className="club-modal-content">
            <h2>{selectedClub.name}</h2>
            <p>{selectedClub.clubDescription}</p>
            <p><strong>Meeting Times:</strong> {selectedClub.meetingTimes}</p>
            <p><strong>Location:</strong> {selectedClub.location}</p>
            {selectedClub.websiteURL && (
                <p><strong>Website URL:</strong> <a href={selectedClub.websiteURL} target="_blank" rel="noopener noreferrer">{selectedClub.websiteURL}</a></p>
            )}
            <p><strong>Phone Number:</strong> {selectedClub.contactPhone}</p>
            <p><strong>Email:</strong> {selectedClub.contactEmail}</p>
            {selectedClub.isApproved ? (
                 <p></p>
            ) : (
                <p><strong>Club Creator:</strong> {posterDetails.name} ({posterDetails.email})</p>
            )}

            <div className="modal-buttons">
            <button onClick={closeModal} className="close-btn">Close</button>


            {selectedClub.isApproved ? (
                <button onClick={() => handleDeleteClub(selectedClub.id)} className="remove-btn">Remove Club</button>
            ) : (
                <>
                    <button onClick={() => approveClub(selectedClub.id, adminSchool)} className="approve-btn">Approve</button>
                    <button onClick={() => declineClub(selectedClub.id, adminSchool)} className="deny-btn">Deny</button>
                </>
            )}
            </div>
        </div>
    )}
</Modal>


      </div>
  );
};

export default ClubsPage;