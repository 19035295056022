import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const Textslider = {
    responsive: {
        0: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
};

const textItems = [
    'Discover',
    'Connect',
    'Meet',
    'Grow',
    'Enjoy',
    'Live',
];

function Textlist() {
    return (
        <>
            <div className="text_list_section row_am" data-aos="fade-in" data-aos-duration={1500} >
                <div className="container">
                </div>
                <div className="slider_block">
                    <Swiper className="swiper-wrapper" id="text_list_flow" {...Textslider} slidesPerView={3}>
                        {textItems.map((item, index) => (
                            <SwiperSlide key={index} className="item">
                                <div className="text_block">
                                    <span>{item}</span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
}

export default Textlist;
