import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import blue_dotes from '../../assets/images/blue_dotes.png'
import keyftr1 from '../../assets/images/clubs-feature.png'
import keyftr2 from '../../assets/images/bulletin-website.png'
import keyftr3 from '../../assets/images/chats-website.png'
import keyftr4 from '../../assets/images/study-website.png'
import keyftr5 from '../../assets/images/resource-website.png'



const Feature = {
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
        1400: {
            margin: 60,
        },
    },
};


const swiperSettings = {
    slidesPerView: 1, 
    spaceBetween: 15, 
    enabled: false,

    breakpoints: {
        // when window width is >= 600px
        576: {
            enabled: true,
        },
        770: {
            enabled: true,
            slidesPerView: 2,
        },
        // when window width is >= 1000px
        1000: {
            enabled: true,
            slidesPerView: 3,
        },
        // Additional settings for larger screens can be added here
    },
};


const featureItems = [
    {
        title: 'Clubs and Organizations',
        description: 'Easily discover clubs and organizations on campus and request to join directly through the app',
        image: keyftr1,
    },
    {
        title: 'Events',
        description: 'Stay in the loop with campus events. Scroll, search, and filter to find events that capture your interest.',
        image: keyftr2,
    },
    {
        title: 'Study Groups',
        description: 'Foster academic collaborations by creating or joining study groups. Connect and learn together.',
        image: keyftr4,
    },
    {
        title: 'Chats',
        description: 'Engage in the pulse of campus life. Voice your thoughts and tune into conversations with classmates.',
        image: keyftr3,
    },
    {
        title: 'Campus Resources',
        description: 'Easily access all of the personal and academic resources your campus has to offer.',
        image: keyftr5,
    },
];

function Keyfeature() {
    return (
        <>
            <section className="key_feature_section row_am pt-0">
                <div className="key_innner">
                    <div className="container">
                        <div className="section_title">
                            {/* <span className="title_badge">Key Features</span> */}
                            <h2>Key Features</h2>
                        </div>
                        <Swiper {...swiperSettings}>
    {featureItems.map((item, index) => (
        <SwiperSlide className="item" key={index}>
            <div className="feature_box">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <div className="img">
                    <img src={item.image} alt="image" />
                </div>
            </div>
        </SwiperSlide>
    ))}
</Swiper>

                        <div className="section_title">
                        <span className="title_badge">Scroll to see more</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Keyfeature;
