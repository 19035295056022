import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './PaymentMethodPage.css';

const stripePromise = loadStripe('pk_live_51OsG8fRvvv49RsIdZci9uRVEqY2GLSZ5EoHJMiO8UNHLK0bYFiqK7rJNztRz33EutlLBfRryZqXGTkHzjqHHoJSM00Yea4z4rK'); // Replace with your actual publishable key

const PaymentMethodPage = () => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentMethodType, setPaymentMethodType] = useState('card');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolderType, setAccountHolderType] = useState('individual');
    const [accountHolderName, setAccountHolderName] = useState('');


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customerId');


    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe) {
            console.error("Stripe has not loaded yet.");
            return;
        }

        console.log('Submitted')
        
        console.log(paymentMethodType)

        let paymentMethodResult;

        if (paymentMethodType === 'card') {
            // Handle card payment
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            if (result.error) {
                console.error(result.error.message);
                return;
            }
            // Attach card payment method to customer
            console.log('Customer ID:', customerId); // Debugging statement
            await attachPaymentMethod(customerId, result.paymentMethod.id, 'card');


        } 
        else if (paymentMethodType === 'us_bank_account') {
            const bankAccountDetails = {
                country: 'US',
                currency: 'usd',
                account_holder_name: accountHolderName, 
                account_holder_type: accountHolderType,
                routing_number: routingNumber,
                account_number: accountNumber,
            };
        
            // Create bank account token (optional, depending on your Stripe configuration)
            const tokenResponse = await stripe.createToken('bank_account', bankAccountDetails);
            if (tokenResponse.error) {
                console.error(tokenResponse.error.message);
                return;
            }
        
            // Create Payment Method using the token or directly using the details
            const paymentMethodResult = await stripe.createPaymentMethod({
                type: 'us_bank_account',
                us_bank_account: {
                    account_number: accountNumber,
                    routing_number: routingNumber,
                    account_holder_type: accountHolderType,
                },
                billing_details: {
                    name: accountHolderName,
                },
            });
            
            if (paymentMethodResult.error) {
                console.error(paymentMethodResult.error.message);
                return;
            }
    
            // Request server to create SetupIntent and get clientSecret
            const setupIntentResponse = await fetch('https://us-central1-collegiate-43b7c.cloudfunctions.net/createBankAccountSetupIntent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    customerId: customerId, // Ensure customerId is available
                })
            });
    
            const setupIntentData = await setupIntentResponse.json();
            if (!setupIntentResponse.ok) {
                console.error('Failed to create SetupIntent:', setupIntentData.error);
                return;
            }

            fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                const userIpAddress = data.ip;
    
                // Confirm the SetupIntent on the client to finalize saving the bank account
                stripe.confirmSetupIntent(setupIntentData.clientSecret, {
                    payment_method: paymentMethodResult.paymentMethod.id,
                    mandate_data: {
                        customer_acceptance: {
                            type: 'online',
                            online: {
                                ip_address: userIpAddress,
                                user_agent: navigator.userAgent
                            }
                        }
                    }
                }).then(confirmResult => {
                    if (confirmResult.error) {
                        console.error('SetupIntent confirmation failed:', confirmResult.error);
                        return;
                    }
    
                    console.log('Bank account attached successfully:', confirmResult.setupIntent.payment_method);
                 
               //     navigate('/confirmation');
                });
            })
            .catch(error => console.error('Error fetching IP:', error));
        }
        
 
    };

    const attachPaymentMethod = async (customerId, paymentMethodId, paymentMethodType) => {
        const response = await fetch('https://us-central1-collegiate-43b7c.cloudfunctions.net/attachPaymentMethod ', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: customerId,
                paymentMethodId: paymentMethodId,
                paymentMethodType: paymentMethodType
            })
        });
        const data = await response.json();
        if (response.ok) {
            console.log('Payment method attached successfully:', data);
        } else {
            console.error('Failed to attach payment method:', data.error);
        }
    };

    return (
        <div className="payment-page-container">
            <div className="payment-page-card">
                <div className="payment-page-left-panel">
                    <div>
                        Payment Options
                    </div>
                    <div className="payment-page-powered-by">
                        Powered by Stripe
                    </div>
                </div>
                <div className="payment-page-right-panel">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Payment Method:</label>
                            <select className="payment-page-select" required onChange={e => setPaymentMethodType(e.target.value)}>
                                <option value="card">Credit Card</option>
                                <option value="us_bank_account">Bank Account</option>
                            </select>
                        </div>
                        {paymentMethodType === 'card' ? (
                            <CardElement className="payment-page-input-field" />
                        ) : (
                            <>
                                <div>
                                    <label>Account Holder Name:</label>
                                    <input className="payment-page-input-field" type="text" value={accountHolderName} onChange={e => setAccountHolderName(e.target.value)} required />
                                </div>
                                <div>
                                    <label>Routing Number:</label>
                                    <input className="payment-page-input-field" type="text" onChange={e => setRoutingNumber(e.target.value)} required />
                                </div>
                                <div>
                                    <label>Account Number:</label>
                                    <input className="payment-page-input-field" type="text" onChange={e => setAccountNumber(e.target.value)} required />
                                </div>
                                <div>
                                    <label>Account Holder Type:</label>
                                    <select className="payment-page-select" onChange={e => setAccountHolderType(e.target.value)} required>
                                        <option value="individual">Individual</option>
                                        <option value="company">Company</option>
                                    </select>
                                </div>
                            </>
                        )}
                        <button className="payment-page-button" type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
    
};

const WrappedForm = () => {
    return(
    <Elements stripe={stripePromise}>
        <PaymentMethodPage />
    </Elements>
    );
};

export default WrappedForm;
