import React from 'react';
import { Link } from 'react-router-dom';
import Google from '../../assets/images/google.png';
import Smilly from '../../assets/images/smily.png';
import Reviewimg from '../../assets/images/review1.png.png';
import Reviewimg1 from '../../assets/images/review2..png';
import Reviewimg2 from '../../assets/images/review3.png.png';

const reviewData = [
    {
        name: 'Winden Brown',
        company: 'San Diego State University',
        stars: 5,
        text: 'Collegiate is really offering something special with their app. I was lucky enough to be one of the first people to try it out and I have to say I can’t wait until it comes to my school. I know it would make a huge difference in getting myself involved on campus and finding groups I\'m interested in and I truly believe that every student will feel the same way.',
        // text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, totam.',
        avatar: Reviewimg,
    },
    {
        name: 'Ciara Soraghan',
        company: 'Cal Poly San Luis Obispo',
        stars: 5,
        text:'The study groups page is what really stood out to me. I like the idea of being able to create study groups with classmates on the app without needing anyone\'s contact information since you can\’t really talk or meet people during class. It\’s a great way to meet new people and create friendships while learning at the same time. ',
       // text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur et, nemo distinctio eum omnis quam!',
        avatar: Reviewimg1,
    },
    {
        name: 'Nathaniel Ferguson',
        company: 'San Diego State University',
        stars: 5,
        text: 'As a commuter student, the only time I really spend around campus is walking to and leaving class. It is somewhat challenging to firstly figure out what’s going on around campus and secondly plan it into my schedule. I think Collegiate does a great job at simplifying the process and would allow me to experience what my school has to offer to its full extent.',
        // text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente culpa, dolores ullam laudantium deleniti ipsa qui saepe voluptatum nam pariatur? Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur et, nemo distinctio eum omnis quam!',
        avatar: Reviewimg2,
    },
];

function Review() {
    return (
        <>
            <section className="review_section row_am">
                <div className="container">
                    <div className="positive_inner">
                        <div className="row">
                            <div className="col-md-6 sticky-top">
                                <div className="sidebar_text"
                                    data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="section_title text-left">
                                        {/* <span className="title_badge">Reviews</span> */}
                                        <h2>
                                            <span>What our users </span> <br /> are saying
                                        </h2>
                                    </div>
                                    <div className="user_review">
                                        <p>
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="review_side">
                                    {reviewData.map((review, index) => (
                                        <div key={index} className="review_block"
                                            data-aos="fade-up" data-aos-duration={1500} >
                                            <div className="coustomer_info">
                                                <div className="avtar">
                                                    <img src={review.avatar} alt="image" />
                                                    <div className="text">
                                                        <h3>{review.name}</h3>
                                                        <span>{review.company}</span>
                                                    </div>
                                                </div>
                                                <div className="star">
                                                    {Array.from({ length: review.stars }, (_, i) => (
                                                        <span key={i}>
                                                            <i className="icofont-star" />
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <p>{review.text}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Review;
