import React from 'react';

const Demo = () => {
  const iframeHTML = `<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="100%" height="100%" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FtFAMXWiMDc4gvWa8TTDx5t%2FCollegiate-App%3Fnode-id%3D1-77%26t%3DzbaR8ScKHBRYSxaa-1%26scaling%3Dscale-down%26page-id%3D0%253A1%26starting-point-node-id%3D1%253A77" allowfullscreen></iframe>`;

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}
      dangerouslySetInnerHTML={{ __html: iframeHTML }}
    />
  );
};

export default Demo;
