import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import ourvalue_1 from '../../assets/images/ourvalue_1.png'
import ourvalue_2 from '../../assets/images/ourvalue_2.png'
import ourvalue_3 from '../../assets/images/ourvalue_3.png'

const Value = {
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
        1400: {
            margin: 60,
        },
    },
};

const swiperSettings = {
    slidesPerView: 1, 
    spaceBetween: 15, 
    breakpoints: {
        // when window width is >= 600px
        600: {
            slidesPerView: 2,
        },
        // when window width is >= 1000px
        1000: {
            slidesPerView: 3,
        },
        // Additional settings for larger screens can be added here
    },
};

const valueItems = [
    {
        icon: ourvalue_1,
        title: 'Student Focused',
        description: 'We are dedicated to providing students with everything they need for a more engaged college experience.',
    },
    {
        icon: ourvalue_2,
        title: 'Community Building',
        description: 'Our various features help students connect with their peers and campuses. Creating a vibrant campus community.',
    },
    {
        icon: ourvalue_3,
        title: 'Adapting to Needs',
        description: 'Student needs are ever-changing. That\'s why Collegiate is ever-evolving to keep providing students with exactly what they need.',
    },
];

function Ourvalue() {
    return (
        <>
            <section className="our_value_section row_am">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <h2>
                            Why <span>Collegiate?</span>
                        </h2>
                    </div>
                    <div className="value_slider" data-aos="fade-in" data-aos-duration={1500}>
                    <Swiper className="swiper-wrapper" id="value_slider" {...swiperSettings}>
                            {valueItems.map((item, index) => (
                                <SwiperSlide className="item" key={index}>
                                    <div className="value_block">
                                        <div className="icon">
                                            <img src={item.icon} alt="image" />
                                        </div>
                                        <div className="text">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Ourvalue;
