import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom"
import Routing from './routes';
import '../src/assets/css/aos.css'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/icofont.min.css'
import '../src/assets/css/magnific-popup.min.css'
import '../src/assets/css/owl.carousel.min.css'
import '../src/assets/css/responsive.css'
import '../src/assets/css/style.css'
import { AuthProvider } from '../src/admin/AuthContext';


{/*}
export default function App() {
  return (
    <>
      <Router >
        <Routing />
      </Router>
    </>
  );
}
*/}

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Routing />
      </Router>
    </AuthProvider>
  );
}