import React, { useEffect, useState } from 'react';
import {auth, db } from '../firebase';
import { firestore } from '../firebase';
import { collection, query, where, getDocs, getDoc, updateDoc, doc, deleteDoc, Timestamp, addDoc} from 'firebase/firestore';
import Modal from 'react-modal';
import './ChatsPage.css';

Modal.setAppElement('#root');

const customModalStyles = {
    content: {
        maxWidth: '50%', 
        maxHeight: '50%', 
        margin: 'auto', // Center the modal
        padding: '20px',
        borderRadius: '10px',
        position: 'relative', // Ensure it's centered
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent overlay
    },
};

const ChatsPage = () => {
    const [chats, setChats] = useState([]);
    const [reportedChats, setReportedChats] = useState([]);
    const [adminSchool, setAdminSchool] = useState('');
    const [activeTab, setActiveTab] = useState('sendChat');

    const [selectedChat, setSelectedChat] = useState(null);
    const [posterDetails, setPosterDetails] = useState({ name: '', email: '' });

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [chatsPerPage, setChatsPerPage] = useState(6); // Default to 6, adjust as needed

    const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [newChat, setNewChat] = useState({
    message: '',
    comments: [],
    isReported: false,
    liked: 0,
});


    const indexOfLastChat = currentPage * chatsPerPage;
    const indexOfFirstChat = indexOfLastChat - chatsPerPage;
    const currentChats = chats.slice(indexOfFirstChat, indexOfLastChat);

    const totalPages = Math.ceil(chats.length / chatsPerPage);

    const updateChatsPerPage = () => {
        const screenHeight = window.innerHeight;
        if (screenHeight >= 1200) {
          setChatsPerPage(24); // Matches 6 rows, 3 columns
        } else if (screenHeight >= 1000) {
          setChatsPerPage(18); // Matches 6 rows, 3 columns
        } else if (screenHeight >= 800) {
          setChatsPerPage(12); // Matches 4 rows, 3 columns
        } else {
          setChatsPerPage(6); // Default case for smaller screens
        }
      };

      useEffect(() => {
        // Update chats per page based on initial screen height
        updateChatsPerPage();
      
        // Add resize event listener to update chats per page on screen resize
        window.addEventListener('resize', updateChatsPerPage);
      
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateChatsPerPage);
      }, []);
      
      



    const handleChatClick = (chat) => {
        setSelectedChat(chat);
        setModalIsOpen(true);
    };
    


    useEffect(() => {
        const fetchAdminSchool = async () => {
            if (auth.currentUser) {
                const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
                if (userDoc.exists()) {
                    setAdminSchool(userDoc.data().school);
                }
            }
        };
    
        fetchAdminSchool();
    }, []);

    useEffect(() => {
        if (adminSchool) {
            fetchChats(adminSchool);
            fetchReportedChats(adminSchool);
        }
    }, [adminSchool]);

    const fetchChats = async (school) => {
        const q = query(collection(db, "chats"), where("school", "==", school)); // Assuming there's an 'isApproved' field to check if the chat is active
        try {
            const querySnapshot = await getDocs(q);
            const fetchedChats = querySnapshot.docs.map(doc => {
                const chatData = doc.data();
                const formattedDate = chatData.date 
    ? new Date(chatData.date.seconds * 1000).toLocaleString() // Converts to local date and time string
    : 'No date';

                return { ...chatData, id: doc.id, formattedDate }; // Add more fields as necessary
            });
            setChats(fetchedChats);
        } catch (error) {
            console.error("Error fetching chats: ", error);
            // Handle errors, e.g., update the UI to show an error message
        }
    };
    

    const fetchReportedChats = async (school) => {
        const q = query(collection(db, "chats"), where("school", "==", school), where("isReported", "==", true));
        try {
            const querySnapshot = await getDocs(q);
            const fetchedReportedChats = querySnapshot.docs.map(doc => {
                const chatData = doc.data();
                const formattedDate = chatData.date 
        ? new Date(chatData.date.seconds * 1000).toLocaleString() // Converts to local date and time string
        : 'No date';
    
                return { ...chatData, id: doc.id, formattedDate }; // Add more fields as necessary
            });
            setReportedChats(fetchedReportedChats);
        } catch (error) {
            console.error("Error fetching reported chats: ", error);
            // Handle errors, e.g., update the UI to show an error message
        }
    };
    
    const handleSendChat = async (e) => {
        e.preventDefault();
    
        // Assuming `auth.currentUser.uid` gives us the current user's ID
        const userId = auth.currentUser ? auth.currentUser.uid : null;
    
        const chatDetails = {
            message: newChat.message,
            publisher: userId, // Using the logged-in user's ID
            school: adminSchool, // This assumes adminSchool has been fetched as before
            date: new Date().toLocaleString("en-US", {
                year: 'numeric', 
                month: '2-digit', 
                day: '2-digit', 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true
            }),
            comments: [], // Assuming we initialize comments as an empty array
            isReported: false, // Initializing as false
            liked: 0, // Initializing likes count as 0
        };
    
        try {
            await addDoc(collection(db, "chats"), chatDetails);
            alert("Chat sent successfully.");
            // Optionally, reset newChat state here
            setNewChat({
                message: '',
                comments: [],
                isReported: false,
                liked: 0,
            });
        } catch (error) {
            console.error("Error sending chat: ", error);
            alert("Failed to send chat.");
        }
    };
    



    const handleDeleteChat = async (chatId) => {
        if (window.confirm("Are you sure you want to delete this chat?")) {
            try {
                await deleteDoc(doc(firestore, "chats", chatId)); // Assumes 'firestore' is your Firestore instance
                alert("Chat deleted successfully.");
                fetchChats(); // Re-fetch chats to update the list after deletion
            } catch (error) {
                console.error("Error deleting chat: ", error);
                alert("Error deleting chat.");
            }
        }
    };

    const handleSelectChat = (chat) => {
        setSelectedChat(chat);
        
        if (chat.publisher) {
            fetchUserDetails(chat.publisher);
        } else {

            setPosterDetails({ name: 'Unknown', email: 'N/A' });
        }
    
        setModalIsOpen(true);
    };
    

    const fetchUserDetails = async (publisher) => {
        try {
            const userRef = doc(db, 'users', publisher); 
            const userDoc = await getDoc(userRef); 
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setPosterDetails({ name: userData.name, email: userData.email });
            } else {
                setPosterDetails({ name: 'Unknown', email: 'N/A' });
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
           
        }
    };
    
    

    

    return (
        <div className="chats-page">
            <div className="tab-switches">
                <button onClick={() => setActiveTab('sendChat')} className={activeTab === 'sendChat' ? 'active' : ''}>Send Chat</button>
                <button onClick={() => setActiveTab('activeChats')} className={activeTab === 'activeChats' ? 'active' : ''}>Active Chats</button>
                <button onClick={() => setActiveTab('reportedChats')} className={activeTab === 'reportedChats' ? 'active' : ''}>Reported Chats</button>
            </div>

            {activeTab === 'sendChat' && (
            <div className="send-chat-section">
                <h2>Post a Chat</h2>
                <form onSubmit={handleSendChat}>
                    <textarea 
                        className="chat-textarea"
                        name="message" 
                        value={newChat.message} 
                        onChange={(e) => setNewChat({ ...newChat, message: e.target.value })} 
                        placeholder="Your message here..."
                    />
                    <button className="send-chat-button" type="submit">Send Chat</button>
                </form>
            </div>
        )}
        {/* Other parts of the component */}
            
            {activeTab === 'activeChats' && (
                <div className="active-chats-section">
                    <h2>Active Chats ({currentChats.length})</h2>
                    {currentChats.length > 0 ? (
                        <div className="chat-cards-container">
                            {currentChats.map((chat) => (
                                <div key={chat.id} className="chat-card" onClick={() => handleSelectChat(chat)}>
                                    <h3>{chat.message}</h3>
                                    <p>{chat.date}</p>
                                    {/* Add more chat details as needed */}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No active chats found.</p>
                    )}
                    {totalPages > 1 && (
                        <div className="pagination">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i}
                                    onClick={() => setCurrentPage(i + 1)}
                                    className={currentPage === i + 1 ? 'active' : ''}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                )}

<Modal 
    isOpen={modalIsOpen} 
    onRequestClose={() => setModalIsOpen(false)} 
    contentLabel="Chat Details"
    style={customModalStyles}
>
    <div className="chat-modal-content">
        <h2>Chat Details</h2>
        {selectedChat && (
            <>
                <h3>{selectedChat.message}</h3>
                <p>Date: {selectedChat.date}</p>
                <p>Posted by: {posterDetails.name} (Email: {posterDetails.email})</p>
                <div className="modal-buttons">
                <button onClick={() => setModalIsOpen(false)} className="close-btn">Close</button>
                <button onClick={() => handleDeleteChat(selectedChat.id)} className="remove-btn">Delete Chat</button>
                
                </div>
            </>
        )}
       
    </div>
</Modal>

{activeTab === 'reportedChats' && (
    <div className="reported-chats-section">
        <h2>Reported Chats ({reportedChats.length})</h2>
        {reportedChats.length > 0 ? (
            <div className="chat-cards-container">
                {reportedChats.slice((currentPage - 1) * chatsPerPage, currentPage * chatsPerPage).map((chat) => (
                    <div key={chat.id} className="chat-card" onClick={() => handleSelectChat(chat)}>
                        <h3>{chat.message}</h3>
                        <p>Date: {chat.date}</p>
                        {/* Add more chat details as needed */}
                    </div>
                ))}
            </div>
        ) : (
            <p>No reported chats found.</p>
        )}
        {Math.ceil(reportedChats.length / chatsPerPage) > 1 && (
            <div className="pagination">
                {Array.from({ length: Math.ceil(reportedChats.length / chatsPerPage) }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i + 1)}
                        className={currentPage === i + 1 ? 'active' : ''}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        )}
    </div>
)}



{/* 
{activeTab === 'reportedChats' && (
    <div className="reported-chats-section">
        <h2>Reported Chats</h2>
        {reportedChats.length > 0 ? (
            <div className="chat-cards-container">
                {reportedChats.map((chat) => (
                    <div key={chat.id} className="chat-card" onClick={() => handleSelectChat(chat)}>
                        <h3>{chat.message}</h3>
                        <p>Date: {chat.date}</p>

                    </div>
                ))}
            </div>
        ) : (
            <p>No reported chats found.</p>
        )}
    </div>
)} */}



        </div>
    );
};

export default ChatsPage;
