// AdminSidebar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './AdminSidebar.css'; 

const AdminSidebar = () => {
    const [hasNewClubRequests, setHasNewClubRequests] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkForNewClubRequests = async () => {
            const q = query(collection(db, "clubs"), where("isApproved", "==", false));
            const querySnapshot = await getDocs(q);
            setHasNewClubRequests(!querySnapshot.empty);
        };

        checkForNewClubRequests();
    }, []);


    const handleLogout = () => {
        auth.signOut().then(() => navigate('/home'));
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <aside className="sidebar">
            <h1 className="admin-title">Admin Dashboard</h1>
            <nav className="admin-menu">
                {/* <Link to="/admin/users" className="admin-menu-item">Users</Link> */}
                <Link to="/admin/bulletin" className="admin-menu-item">Bulletin</Link>
                <Link to="/admin/clubs" className="admin-menu-item">
                    Clubs
                    {hasNewClubRequests && <span className="notification-indicator"></span>}
                </Link>
                <Link to="/admin/chats" className="admin-menu-item">Chats</Link>
                <Link to="/admin/resources" className="admin-menu-item">Resources</Link>
                <Link to="/admin/study-groups" className="admin-menu-item">Study Groups</Link>
                
            </nav>
            <button onClick={handleLogout} className="logout-button">Logout</button>
        </aside>
    );
};

export default AdminSidebar;
