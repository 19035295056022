import React, { useState } from 'react'

function Faq() {

    const [tabMenu, tabActive] = useState({ a: true });
    const [activeFaq, setActiveFaq] = useState({ a: true })

    const [openAccordion, setOpenAccordion] = useState(null);

    const [first, setfirst] = useState(null);

    const toggleAccordion = (index) => {
        if (openAccordion === index) {
            setOpenAccordion(null);
        } else {
            setOpenAccordion(index);
        }
    };

    const togglefirst = (index) => {
        if (first === index) {
            setfirst(null);
        } else {
            setfirst(index);
        }
    };

    const accordionData = [
        {
            question: 'How do I get started with the app?',
            answer:
                'If your school has registered with Collegiate, simply download Collegiate, select your school, and create an account with your school email.',
        },
        {
            question: 'Can I have a private account?',
            answer: 'For the saftey of all the students, private accounts are not an option. The only information you are required to display is your name, major, and graduation year. Everything else is optional.',
        },
        {
            question: 'How can I delete my account?',
            answer: 'To delete your account, navigate to your profile and click on the edit profile button. Then navigate to the bottom and select "Delete my Account". Once you delete your account, all of your information is deleted. ',
        },
        {
            question: 'What should I do if I forgot my password?',
            answer: ' If you forget your password, select your school, click login, enter your email then select forgot password. You will recieve an email prompting you to set a new password.',
        },
    ];

    const firstData = [
        {
            question: 'How can our school register for Collegiate?',
            answer: 'To register your school with Collegiate, contact us via the contact form below or by emailing:  info@collegiateapp.co',
        },
        {
            question: 'Is our students information safe?',
            answer: 'Yes, at Collegiate, our only goal is to provide students with a more engaging college experience. We promise to never share or sell your students information.',
        }, 
        {
            question: 'Is there a way to customize the app experience for our school?',
            answer: 'Yes, the app will be branded with your school colors.',
        },
        {
            question: ' What measures are in place to ensure user safety and privacy?',
            answer: 'Saftey and privacy are our number one priority. Those deemed administrators will have access to the Admin Dashboard where they will have full control over all the information present on the app.',
        },

    ]


    return (
        <>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        {/* <span className="title_badge">Question &amp; Answer</span> */}
                        <h2>
                            Frequently Asked Questions
                        </h2>
                    </div>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration={1500} >
                        <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="genral" role="tabpanel" aria-labelledby="genral-tab" >
                            <div className="accordion" id="accordionExample">
                                <div className="faq-row">
                                    <div className="col-md-6">
                                    <h2 className="faq-title">For Students</h2>
                                        <div className="accordion" id="accordionExample">
                                            {accordionData.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={`heading${index}`}>
                                                        <h2 className="mb-0">
                                                            <button className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                                                                type="button" onClick={() => toggleAccordion(index)}
                                                                aria-expanded={openAccordion === index ? 'true' : 'false'} aria-controls={`collapse${index}`} > {item.question}
                                                                <span className="icons">
                                                                    <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                        className={`collapse ${openAccordion === index ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#accordionExample" >
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                    <h2 className="faq-title">For Administrators</h2>
                                        <div className="first" id="firstExample">
                                            {firstData.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={`heading${index}`}>
                                                        <h2 className="mb-0">
                                                            <button className={`btn btn-link btn-block text-left ${first === index ? '' : 'collapsed'}`}
                                                                type="button" onClick={() => togglefirst(index)}
                                                                aria-expanded={first === index ? 'true' : 'false'} aria-controls={`collapse${index}`} > {item.question}
                                                                <span className="icons">
                                                                    <i className={`icofont-${first === index ? 'minus' : 'plus'}`}></i>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                        className={`collapse ${first === index ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#firstExample" >
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={`tab-pane fade ${tabMenu.b && "show active"}`} id="pricing"
                            role="tabpanel" aria-labelledby="pricing-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.c && "show active"}`} id="account"
                            role="tabpanel" aria-labelledby="account-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.d && "show active"}`}
                            id="returns" role="tabpanel" aria-labelledby="returns-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.e && "show active"}`}
                            id="support" role="tabpanel" aria-labelledby="support-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq