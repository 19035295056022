import React, { useRef }  from 'react'
import Bluedots from '../../assets/images/blue_dotes.png'
import emailjs from '@emailjs/browser';



function Contactform() {

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ldz0gfi', 'template_ujsbueu', form.current, 'NokvXyr53zWOorB8w')
      .then((result) => {
          alert('Message sent successfully');
          console.log(result.text);
           e.target.reset(); 
      }, (error) => {
          alert('Failed to send message. Please try again later.');
          console.log(error.text);
      });
  };

    return (
        <>
            <section className="contact_form white_text row_am" data-aos="fade-in" data-aos-duration={1500} >
                <div className="contact_inner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={Bluedots} alt="image" />
                        </div>
                        <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100} >
                            {/* <span className="title_badge">Learn more</span> */}
                            <h2>Contact us</h2>
                            <p>Are you a school administrator interested in Collegiate for your campus? <br /> Contact us below! </p>
                        </div>
                        <form ref={form} onSubmit={sendEmail} data-aos="fade-up" data-aos-duration={1500}>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text"  name="first_name" className="form-control" placeholder="Name *" required="" />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="email" name="email" className="form-control" placeholder="Email *" required="" />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" name="school_name" className="form-control" placeholder="Institution *" required="" />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text"  name="phone_number" className="form-control" placeholder="Phone" />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" placeholder="Message" defaultValue={""} />
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="coustome_checkbox">
                                        <label htmlFor="term_checkbox">
                                            <input type="checkbox" id="term_checkbox" />
                                            <span className="checkmark" />I agree to receive emails,
                                            newsletters and promotional messages
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4 text-right">
                                    <div className="btn_block">
                                        <button type="submit" className="btn puprple_btn ml-0">Submit</button>
                                        <div className="btn_bottom" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contactform