import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import appscreen from '../../assets/images/about-us-image.png'
import Application from '../../assets/images/applicationvideothumb.png'
import Playbtn from '../../assets/images/play_white.svg'
import Videobtn from '../../assets/images/playvideotext.png'
import Mobileicon from '../../assets/images/mobileicon.png'
import Usericon from '../../assets/images/usericon.png'
import Magneticon from '../../assets/images/magnet.png'


function Aboutus() {

    const [counts, setCounts] = useState({
        download: 0,
        reviews: 1500,
        countries: 0,
        followers: 0,
    });

    useEffect(() => {
        const updateCounter = () => {
            const targetCounts = {
                download: 17,
                reviews: 2300,
                countries: 150,
                followers: 8,
            };

            const duration = 1500;
            const stepSize = 10;
            const steps = duration / stepSize;

            Object.keys(targetCounts).forEach((key) => {
                const targetCount = targetCounts[key];
                const currentCount = counts[key];
                const step = Math.ceil((targetCount - currentCount) / steps);

                if (step !== 0) {
                    const interval = setInterval(() => {
                        setCounts((prevCounts) => ({
                            ...prevCounts,
                            [key]: prevCounts[key] + step,
                        }));
                    }, stepSize);

                    setTimeout(() => {
                        clearInterval(interval);
                        setCounts((prevCounts) => ({
                            ...prevCounts,
                            [key]: targetCount,
                        }));
                    }, duration);
                }
            });
        };

        updateCounter();
    }, []);


    const [ytshow, setytShow] = useState(false);

    return (
        <>
            <section className="about_section row_am">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        
                        <h2>
                            The only all in one app for college student extracurricular activies.
                        </h2>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration={1500} >
                                <li data-aos="fade-right" data-aos-duration={1500}>
                                    <div className="text">
                                        <p><span>Academic</span></p>
                                    </div>
                                </li>
                                <li data-aos="fade-right" data-aos-duration={1500}>
                                    <div className="text">
                                    <p><span>Social</span></p>
                                    </div>
                                </li>
                                {/* <li data-aos="fade-right" data-aos-duration={1500}>
                                    <div className="text">
                                    <p><span>Personal</span></p>
                                    </div>
                                </li>
                                <li data-aos="fade-right" data-aos-duration={1500}>
                                    <div className="text">
                                        <p><span>Professional</span></p>
                                    </div>
                                </li> 
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="abtImg text-center" data-aos="fade-up" data-aos-duration={1500} >
                                <img src={appscreen} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                        <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration={1500} >
                        <li data-aos="fade-right" data-aos-duration={1500}>
                                    <div className="text">
                                    <p><span>Personal</span></p>
                                    </div>
                                </li>
                                <li data-aos="fade-right" data-aos-duration={1500}>
                                    <div className="text">
                                        <p><span>Professional</span></p>
                                    </div>
                                    </li>
                                    </ul>
                        </div>
                         <div className="col-lg-4">
                            <p data-aos="fade-up" data-aos-duration={1500}>
                            Collegiate is designed with the modern student in mind, 
                            offering a comprehensive suite of tools covering every 
                            aspect of campus life.  <br />  <br /> Whether it's discovering new clubs and events, 
                            coordinating study groups, or accessing essential campus resources, 
                            our platform simplifies and enriches the student experience.  <br /> <br />
                            By centralizing academic and social resources, we empower students to 
                            excel in their educational, personal, and professional life.<br /><br />
                            </p>
                            <div className="btn_block" data-aos="fade-up" data-aos-duration={1500}>
                                <Link to="/contact" className="btn puprple_btn ml-0">
                                    Learn How
                                </Link>
                                <div className="btn_bottom" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            {ytshow &&
                <div className="modal fade youtube-video show" id="myModal"
                    tabIndex={-1} style={{ display: "block", paddingRight: 17 }}
                    aria-modal="true" role="dialog" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" onClick={() => setytShow(false)}>
                            <button id="close-video"
                                type="button" className="button btn btn-default text-right" data-dismiss="modal" onClick={() => setytShow(false)}>
                                <i className="icofont-close-line-circled" />
                            </button>
                            <div className="modal-body">
                                <div id="video-container" className="video-container">
                                    <iframe id="youtubevideo"
                                        width={640} height={360}
                                        allowFullScreen=""
                                        src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Aboutus