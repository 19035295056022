import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../admin/AuthContext'; 
import { auth } from '../firebase.js';



const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  console.log('Current user in PrivateRoute:', currentUser);
  return currentUser ? children : <Navigate to="/home" />;
};



export default PrivateRoute;
