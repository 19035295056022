import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation';
import Banner1 from '../../assets/images/banavt1.png'
import Banner2 from '../../assets/images/banavt2.png'
import Banner3 from '../../assets/images/banavt3.png'
import Play from '../../assets/images/play.svg'
import googleplay from '../../assets/images/googleplay.png'
import appstorebtn from '../../assets/images/appstorebtn.png'
import smallStar from '../../assets/images/smallStar.png'
import bigstar from '../../assets/images/bigstar.png'
import hero from '../../assets/images/homepage-website.png'

function Banner() {

    const [ytban, setytban] = useState(false);

    return (
        <>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="banner_text">
                                <div className="type-wrap">
                                    <TypeAnimation id="typed"
                                        sequence={['Discover clubs and organizations.', 500, 'Chat with your school.', 500, 'Discover what\'s happening.', 500, 'Join and create study groups.', 500]}
                                        style={{ whiteSpace: "pre" }} repeat={Infinity} />
                                </div>
                                <h1>
                                    Enhancing the <span>Overall Student Experience</span>
                                </h1>
                                <p>
                                   Discover Collegiate, the all in one app designed to seamlessly integrate every aspect of student life.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="banner_slider">
                                <div className="hero_img">
                                    <img src={hero} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {ytban &&
                <div className="modal fade youtube-video show" id="myModal"
                    tabIndex={-1} style={{ display: "block", paddingRight: 17 }}
                    aria-modal="true" role="dialog" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" onClick={() => setytban(false)}>
                            <button id="close-video"
                                type="button" className="button btn btn-default text-right" data-dismiss="modal" onClick={() => setytban(false)}>
                                <i className="icofont-close-line-circled" />
                            </button>
                            <div className="modal-body">
                                <div id="video-container" className="video-container">
                                    <iframe id="youtubevideo"
                                        width={640} height={360} allowFullScreen=""
                                        src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1" />
                                </div>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default Banner