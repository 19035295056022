import React, { useState } from 'react'

function Faq() {

    const [tabMenu, tabActive] = useState({ a: true });
    const [activeFaq, setActiveFaq] = useState({ a: true })

    const [openAccordion, setOpenAccordion] = useState(null);

    const [first, setfirst] = useState(null);

    const toggleAccordion = (index) => {
        if (openAccordion === index) {
            setOpenAccordion(null);
        } else {
            setOpenAccordion(index);
        }
    };

    const togglefirst = (index) => {
        if (first === index) {
            setfirst(null);
        } else {
            setfirst(index);
        }
    };

    const accordionData = [
        {
            question: 'I can\'t find my school in the list.',
            answer:
                'If you cannot find your school in the list, make sure you are updated to the newest version of the app. If you still cannot find it, then your school has not signed up for the app yet and it is not available at your campus.',
        },
        {
            question: 'I can\'t create an account',
            answer: 'To create an account, make sure that you have your school selected and that you are using your school provided email address. If the problem persists, close the app, select your school again, and retry. If the problem still persists contact our support team below.',
        },
        {
            question: 'I can\'t log in.',
            answer: 'If you cannot login, make sure that when you select your school it shows your school colors as the background. Make sure all your information is correct and if needed reset your password.',
        },
        {
            question: 'Password resest not working.',
            answer:'To recieve the password reset email, you have to enter your account email first. If the problem persists, contact us with the support email below.',
        },
        {
            question: 'My social media links are not working.',
            answer: 'To add your Instagram and LinkedIn accounts, make sure you are entering them in the correct format. For example: user_name. Make sure not to include the @ or any links.',
        },
        {
            question: 'I can\'t join a club.',
            answer:'Sending a request to join a club or organization does not automatically add you into the clubs members. An admin has to approve you. Check your email for any messages from the club administrator, they might be requesting more information.',
        },
        {
            question: 'I can\'t request to create a club.',
            answer: ' Make sure all the necessary information is provided before sending a request to create a club. A campus administrator has to approve the club before it appears on the app. Check your email for any messages from the campus administrator, they might be requesting more information.',
        },
        {
            question: 'The study group I created is not showing up.',
            answer: 'If you cannot see the study group you created on your page or the study group tab, refresh both pages and it will be there. If the problem persists, close and reopen the app and check again.',
        },
        {
            question: 'I can\'t join a study group.',
            answer: 'Email us below letting us know the problem you are having and we will fix it.',
        },
        {
            question: 'I can\'t add a study group to my calendar.',
            answer: 'Make sure you have the caldenar permission turned on for the Collegiate App. Go to Settings, Collegiate, Select Calender, and turn on permissions.',
        },
        {
            question: 'The chat I posted is not showing up.',
            answer: 'If your chat isn\'t showing up immideatly, refresh the tab and it should be there. If not try posting it again. If the problem persists, email us below with the problem you are having and we will fix it.',
        },
        {
            question: 'Don\'t see your problem?',
            answer: 'Reach out to us at info@collegiateapp.co, or click the email icon below with a message telling us what the problem you are having is.',
        },

        
    ];

    const firstData = [
        {
            question: 'How can our school register for Collegiate?',
            answer: 'To register your school with Collegiate, contact us via the contact form below or by emailing:  info@collegiateapp.co',
        },
        {
            question: 'Is our students information safe?',
            answer: 'Yes, at Collegiate, our only goal is to provide students with a more engaging college experience. We promise to never share or sell your students information.',
        }, 
        {
            question: 'Is there a way to customize the app experience for our school?',
            answer: 'Yes, the app will be branded with your school colors.',
        },
        {
            question: ' What measures are in place to ensure user safety and privacy?',
            answer: 'Saftey and privacy are our number one priority. Those deemed administrators will have access to the Admin Dashboard where they will have full control over all the information present on the app.',
        },

    ]


    return (
        <>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        {/* <span className="title_badge">Question &amp; Answer</span> */}
                        <h2>
                            Support Questions
                        </h2>
                    </div>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration={1500} >
                        <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="genral" role="tabpanel" aria-labelledby="genral-tab" >
                            <div className="accordion" id="accordionExample">
                                <div className="support-row">
                                    <div className="col-md-6">
                                    {/* <h2 className="faq-title">App For Students</h2> */}
                                        <div className="accordion" id="accordionExample">
                                            {accordionData.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={`heading${index}`}>
                                                        <h2 className="mb-0">
                                                            <button className={`btn btn-link btn-block text-left ${openAccordion === index ? '' : 'collapsed'}`}
                                                                type="button" onClick={() => toggleAccordion(index)}
                                                                aria-expanded={openAccordion === index ? 'true' : 'false'} aria-controls={`collapse${index}`} > {item.question}
                                                                <span className="icons">
                                                                    <i className={`icofont-${openAccordion === index ? 'minus' : 'plus'}`}></i>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                        className={`collapse ${openAccordion === index ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#accordionExample" >
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

{/* 
                                    <div className="col-md-6">
                                    <h2 className="faq-title">Web For Administrators</h2>
                                        <div className="first" id="firstExample">
                                            {firstData.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={`heading${index}`}>
                                                        <h2 className="mb-0">
                                                            <button className={`btn btn-link btn-block text-left ${first === index ? '' : 'collapsed'}`}
                                                                type="button" onClick={() => togglefirst(index)}
                                                                aria-expanded={first === index ? 'true' : 'false'} aria-controls={`collapse${index}`} > {item.question}
                                                                <span className="icons">
                                                                    <i className={`icofont-${first === index ? 'minus' : 'plus'}`}></i>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                        className={`collapse ${first === index ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#firstExample" >
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>


                        <div className={`tab-pane fade ${tabMenu.b && "show active"}`} id="pricing"
                            role="tabpanel" aria-labelledby="pricing-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.c && "show active"}`} id="account"
                            role="tabpanel" aria-labelledby="account-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.d && "show active"}`}
                            id="returns" role="tabpanel" aria-labelledby="returns-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.e && "show active"}`}
                            id="support" role="tabpanel" aria-labelledby="support-tab" >
                            <h2>FAQ Content Gose Here...</h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq