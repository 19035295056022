// Layout.js
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from './admin/AdminSidebar'; // This is your sidebar component
import './Layout.css'; // Include any additional layout styles if necessary

const Layout = () => {
  return (
    <div className="admin-dashboard">
      <AdminSidebar />
      <div className="content-area">
        <Outlet /> {/* Placeholder for the main content */}
      </div>
    </div>
  );
};

export default Layout;
