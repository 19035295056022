import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51OsG8fRvvv49RsIdZci9uRVEqY2GLSZ5EoHJMiO8UNHLK0bYFiqK7rJNztRz33EutlLBfRryZqXGTkHzjqHHoJSM00Yea4z4rK'); // Replace with your actual publishable key

const BankAccountForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState({
        line1: '',
        city: '',
        state: '',
        postal_code: '',
        country: ''
    });
    const [phone, setPhone] = useState('');
    const [accountHolderType, setAccountHolderType] = useState('individual');

    const [paymentMethodType, setPaymentMethodType] = useState('card'); // Default to 'card'
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            alert("Stripe has not loaded yet.");
            return;
        }

        if (paymentMethodType === 'card') {
            const cardElement = elements.getElement(CardElement);
            const paymentMethodResult = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: name,
                    email: email,
                    address: {
                        line1: address.line1,
                        city: address.city,
                        state: address.state,
                        postal_code: address.postal_code,
                        country: address.country,
                    },
                    phone: phone,
                },
            });

            handleResponse(paymentMethodResult);
        } else if (paymentMethodType === 'bank_account') {
            // Handle bank account payment
            // Ensure you have implemented your backend function for initiating bank account verification
            const response = await fetch('https://us-central1-collegiate-43b7c.cloudfunctions.net/createFinancialConnectionSession', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    customerId: 'your-customer-id' // ensure you have the customer ID here
                })
            });
            const data = await response.json();
            if (response.ok) {
                window.location.href = data.url; // Redirects user to Stripe
            } else {
                console.error('Error initiating financial connection:', data.error);
                alert('Error initiating financial connection: ' + data.error);
            }
        }
    };

    async function handleResponse(paymentMethodResult) {
        // Assuming you have implemented your backend function for creating customer and attaching payment method
        const response = await fetch('https://us-central1-collegiate-43b7c.cloudfunctions.net/createCustomerAndSetupPayment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                paymentMethodResult,
                email,
                name,
                address,
                phone,
                accountHolderType,
            }),
        });

        const responseData = await response.json();
        if (response.ok) {
            console.log('Customer created and payment method saved successfully:', responseData);
            alert('Customer created and payment method saved successfully!');
        } else {
            throw new Error(responseData.message || "Failed to attach payment method to customer");
        }
    }

    
    return (

        <form onSubmit={handleSubmit}>
            <input type="text" required onChange={e => setName(e.target.value)} placeholder="Name"/>
            <input type="email" required onChange={e => setEmail(e.target.value)} placeholder="Email"/>
            <input type="text" required onChange={e => setAddress({...address, line1: e.target.value})} placeholder="Address Line 1"/>
            <input type="text" required onChange={e => setAddress({...address, city: e.target.value})} placeholder="City"/>
            <input type="text" required onChange={e => setAddress({...address, state: e.target.value})} placeholder="State"/>
            <input type="text" required onChange={e => setAddress({...address, postal_code: e.target.value})} placeholder="Postal Code"/>
            <input type="text" required onChange={e => setAddress({...address, country: e.target.value})} placeholder="Country"/>
            <input type="tel" required onChange={e => setPhone(e.target.value)} placeholder="Phone Number"/>
            
                        <div>
                <label>Payment Method:</label>
                <select required onChange={e => setPaymentMethodType(e.target.value)}>
                    <option value="card">Credit Card</option>
                    <option value="bank_account">Bank Account</option>
                </select>
            </div>
            
{paymentMethodType === 'card' ? (
            <CardElement />
        ) : (
            <>
                <div>
                    <label>Routing Number:</label>
                    <input type="text" onChange={e => setRoutingNumber(e.target.value)} required />
                </div>
                <div>
                    <label>Account Number:</label>
                    <input type="text" onChange={e => setAccountNumber(e.target.value)} required />
                </div>
                <div>
                    <label>Account Holder Type:</label>
                    <select onChange={e => setAccountHolderType(e.target.value)} required>
                        <option value="individual">Individual</option>
                        <option value="company">Company</option>
                    </select>
                </div>
            </>
        )}
            <button type="submit" disabled={!stripe}>Submit</button>
        </form>

);
};

const WrappedForm = () => {
    return(
    <Elements stripe={stripePromise}>
        <BankAccountForm />
    </Elements>
    );
};

export default WrappedForm;
