import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

// Replace this with your project's configuration
const firebaseConfig = {
    apiKey: "AIzaSyCBHBlzVkDLFczegG-Phsw0U9tCrl2Kci0",
    authDomain: "collegiate-43b7c.firebaseapp.com",
    projectId: "collegiate-43b7c",
    storageBucket: "collegiate-43b7c.appspot.com",
    messagingSenderId: "520792040643",
    appId: "1:520792040643:web:9b254b0ab45df26b4fae76",
    measurementId: "G-G898SXG443"
};

const app = initializeApp(firebaseConfig);


firebase.initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

// Initialize Firebase
export const firestore = getFirestore(app);
export const db = firebase.firestore();
export const storage = getStorage(app);

// Initialize Firebase Authentication
export const auth = getAuth(app);


// Example: Sign out
auth.signOut();


export default app;